import React from "react";

import "../shared/EntryForm.css";
import "../shared/PromptMessages.css";

export const InfoPrompt = ({ 
                            visible,
                            children 
                          }) => {
  if (visible) {
    return (
      <div className={"alert alert-info prompt-message prompt-info"} role={"alert"}>
        <h1>
          <i className={"fa-solid fa-circle-exclamation"}></i>
          Info
        </h1>
        {children}
      </div>
    )
  }
  else {
    return (<></>)
  }
}

export const WarningPrompt = ({ 
                                visible,
                                children 
                              }) => {
  if (visible) {
    return (
      <div className={"alert alert-warning prompt-message prompt-warning"} role={"alert"}>
        <h1>
          <i className={"fa-solid fa-triangle-exclamation"}></i>
          Warning
        </h1>
        {children}
      </div>
    )
  }
  else {
    return (<></>)
  }
}

export const ErrorPrompt = ({
                              visible,
                              children 
                            }) => {
  if (visible) {
    return (
      <div className={"alert alert-danger prompt-message prompt-danger"} role={"alert"}>
        <h1>
          <i className={"fa-solid fa-circle-xmark"}></i>
          Error
        </h1>
        {children}
      </div>
    )
  } else {
    return (<></>)
  }
}

export const PromptButton = ({
                              disabled, onClick,
                              children,
                            }) => {
  return (
    <button type={"button"} className={"btn"} disabled={disabled}
            onClick={onClick}>
      {children}
    </button>
  )
}
