import React from "react";

import { formatCurrency } from "../../repos/utilities";

import "./ProgressBar.css";

export const progressTypes = {
  default: 1,
  info: 2,
  warning: 3,
  error: 4,
};

const progressColors = {
  1: '#0cf2ad',
  2: '#60c7dd',
  3: '#f1ce67',
  4: '#df727d',
};

export const ProgressBar = ({
                              type, sideLabel, tooltipLabel, isFormatted,
                              progressAmount, totalAmount,
                            }) => {
  const getBarColor = () => {
    if (type === null || type === undefined) {
      return progressColors[progressTypes.default];
    } 
    else {
      return progressColors[type];
    }
  }

  const getBarTooltipDisplay = () => {
    const _isFormatted = isFormatted !== null ? isFormatted : false;
    const _amount = _isFormatted ? formatCurrency(progressAmount, 0) : progressAmount;
    const _total = _isFormatted ? formatCurrency(totalAmount, 0) : totalAmount;
    const _percent = getPercent();
    return `[${_percent}] ${_amount} / ${_total} ${tooltipLabel ?? ""}`;
  }

  const getPercent = () => {
    const _amount = progressAmount ? parseFloat(progressAmount) : 0.0;
    const _total = totalAmount ? parseFloat(totalAmount) : 0.0;
    const _percent = (_amount * 100) / _total;
    return `${Math.round(_percent)}%`;
  }

  return (
    <div className={"progressbar-container"}>
      <div className={"progressbar"}>
        {sideLabel && 
          <div className={"progressbar-label"}>
            {sideLabel}
          </div>
        }
        <div className={"progressbar-bar"} title={getBarTooltipDisplay()}>
          <div className={"progressbar-bar-foreground"} style={{ width: getPercent(), backgroundColor: getBarColor() }}></div>
        </div>
      </div>
    </div>
  )
}
