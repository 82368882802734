import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { inventoryServices } from "../../repos/apiServices";
import { technicalServices } from "../../repos/apiServices2";
import { customerServices as customerServices2 } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { getRegionDisplay } from "../../repos/utilities";
import { getRegionDisplayByName } from "../../repos/utilities";
import { isUnderWarrantyFromStatus } from "../../repos/generatorUtilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { JobStatusBadge } from "./JobStatusBadge";
import { ServiceProfileStatus } from "./ServiceProfileStatus";
import { NullBlankValue } from "./NullBlankField";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { SortIndicator } from "../shared/SortIndicator";
import { ActorPureNameDisplay } from "../sales/ActorNameDisplay";
import { TabsPanelProvider } from "../shared/TabsPanelContext";
import { TabContent } from "../shared/TabsPanelContext";
import { TabContentPane } from "../shared/TabsPanelContext";
import { TabHeader } from "../shared/TabsPanelContext";
import { TabHeaderItem } from "../shared/TabsPanelContext";
import { NewContactLogModal } from "./NewContactLogModal";

import "./ServiceProfilePage.css";
import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "../shared/DataTable.css";
import { customer } from "../../repos/constants";


export function ServiceProfilePage() {
  //#region States
  const [customerId, setCustomerId] = useState(null);
  const [serviceProfileId, setServiceProfileId] = useState(null);
  const [serviceProfile, setServiceProfile] = useState(null);

  const [customerName, setCustomerName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessTypeName, setBusinessTypeName] = useState("");
  const [address, setAddress] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [nrc, setNrc] = useState("");
  const [designation, setDesignation] = useState("");

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isLoadingGenerators, setIsLoadingGenerators] = useState(false);
  const [serviceLocations, setServiceLocations] = useState([]);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    let _customerId = state['customerId'];
    let _profileId = state['serviceProfileId'];
    if (!state || !_customerId || !_profileId) {
      navigate(routes.serviceProfiles.url);
    }
    else {
      setCustomerId(_customerId);
      setServiceProfileId(_profileId);
      fetchCustomerServiceProfile(_customerId, _profileId);
    }
  }, []);

  const fetchCustomerServiceProfile = (customerId, serviceProfileId) => {
    setIsLoadingProfile(true);
    let params = {
      'uid': auth.getUserId(),
      'customer_id': customerId,
      'service_profile_id': serviceProfileId,
    };
    technicalServices.fetchCustomerServiceProfile(params)
      .then((response) => {
        let _serviceProfile = response['data'];
        setServiceProfile(_serviceProfile);
        prepareCustomerDetails(_serviceProfile);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setIsLoadingProfile(false);
      });

    setIsLoadingGenerators(true);
    technicalServices.fetchServiceProfileGenerators(serviceProfileId)
      .then((response) => {
        let _responseData = response['data'];
        setServiceLocations(_responseData);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setIsLoadingGenerators(false);
      });

    fetchProfileContactLogs(serviceProfileId);
  }

  const handleErrorResponse = (error) => {
    let errorResponse = error['response'];
    if (errorResponse) {
      if (errorResponse.status === 404 || errorResponse.status === 400) {
        navigate(routes.serviceProfiles.url);
      }
    }
  }

  const prepareCustomerDetails = (serviceProfile) => {
    if (!serviceProfile) {
      return;
    }
    setCustomerName(serviceProfile['customerName']);
    setCompanyName(serviceProfile['companyName']);
    setBusinessTypeName(serviceProfile['businessTypeName']);
    setAddress(serviceProfile['address']);
    setContactName(serviceProfile['contactFullName']);
    setContactNo(serviceProfile['contactNo']);
    setNrc(serviceProfile['nrc']);
    setDesignation(serviceProfile['designation']);
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    fetchCustomerServiceProfile(customerId, serviceProfileId);
  }

  const onManageLocationsClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.manageProfileLocations.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onNewCommissionClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.newGeneratorCommission.url, {
        state: {
          'customerId': customerId,
          'profileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onManageUsersClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.managePortalUsers.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onGeneratorRowClicked = (ev, generator) => {
    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'generatorId': generator['id'],
          'profileId': serviceProfileId,
          'customerId': customerId,
        }
      });
    }, 200);
  }
  //#endregion

  //#region Profile Care Contact Logs
  const [isLoadingContactLogs, setIsLoadingContactLogs] = useState(false);
  const [profileContactLogs, setProfileContactLogs] = useState([]);

  const fetchProfileContactLogs = (serviceProfileId) => {
    setProfileContactLogs([]);
    setIsLoadingContactLogs(true);

    customerServices2.fetchServiceProfileContactLogs(serviceProfileId)
      .then((response) => {
        let _responseData = response['data'];
        setProfileContactLogs(_responseData['data']);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setIsLoadingContactLogs(false);
      });
  }

  const onProfileContactLogRowClicked = (ev, contactLog) => {
    // NOTE(yemon): 'serviceHistoryId' field is representing the 'id' value of care contact log
    // for the Service Profile entries. This is just that way to maintain consistency
    // with the other listing queries (which utilizes unions) with the service history contact logs
    // as well. Don't get confused.
    const careContactId = contactLog['serviceHistoryId'];
    navigate(routes.contactLogProfileEntry.url, {
      state: {
        'serviceHistoryId': careContactId,
        'serviceProfileId': serviceProfileId,
        'customerId': customerId,
      }
    });
  }
  //#endregion

  //#region Utilities
  const isLoading = () => {
    return isLoadingProfile || isLoadingGenerators || isLoadingContactLogs;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container">
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfiles.displayShort} anchorTo={routes.serviceProfiles.url} />
              <BreadcrumbItem text={customerName} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{customerName}</h1>
          </div>

          <div className={"form-section"}>
            <h2>Customer & Contact Details</h2>

            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Customer Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Company:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Business Type:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Address:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {customerName}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={companyName} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {businessTypeName}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={address} />
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Contact Name:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Contact No:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Contact NRC:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Contact Designation:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {contactName}
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {contactNo}
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={nrc} />
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={designation} />
                </ReadonlyField>
              </div>
            </div>
          </div>

          <div className={"form-section"}>
            <h2>Profile Details</h2>

            <div className={"entry-form"}>
              <div className={"form-label"}>
                <label>
                  Profile Status:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceProfile &&
                    <ServiceProfileStatus profileStatus={serviceProfile['status']}
                                          profileActiveDatetime={serviceProfile['activeDatetime']}
                                          profileInactiveDatetime={serviceProfile['inactiveDatetime']}
                    />
                  }
                </ReadonlyField>
              </div>
            </div>
          </div>

          <div className={"form-section-controls"}>
            <h2>Manage Service Profile</h2>

            <div className={"form-button-controls"}>
              <div className={"left-side wide-col"}>
                <button className={"btn btn-secondary right-margin"} disabled={isLoading()}
                        onClick={onRefreshClicked}>
                  {isLoading() && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                  {!isLoading() && <i className="fa-solid fa-rotate"></i>}
                  Refresh
                </button>
              </div>

              <div className={"right-side narrow-col right-aligned"}>
                <button className={"btn btn-secondary"} disabled={isLoading()}
                        onClick={onManageLocationsClicked}>
                  <i className={"fa-solid fa-location-dot"}></i>
                  Manage Locations
                </button>

                <button className={"btn btn-secondary"} disabled={isLoading()}
                        onClick={onManageUsersClicked}>
                  <i className={"fa-solid fa-user-gear"}></i>
                  Manage Portal Users
                </button>
              </div>
            </div>
          </div>

          <TabsPanelProvider initialHeaderElementId={"service-generators-tab"}
                             initialContentPaneElementId={"service-generators-content"}>
            <TabHeader>
              <TabHeaderItem itemElementId={"service-generators-tab"} contentPaneElementId={"service-generators-content"}
                             itemLabel={"Commissioned Generators"} />
              <TabHeaderItem itemElementId={"profile-contact-logs-tab"} contentPaneElementId={"profile-contact-logs-content"}
                             itemLabel={"Care Contact Logs"} />
            </TabHeader>
            <TabContent>
              <ServiceGeneratorsTabPane serviceLocations={serviceLocations} isLoading={isLoading}
                                        onNewCommissionClicked={onNewCommissionClicked}
                                        onGeneratorRowClicked={onGeneratorRowClicked} />
              <ProfileContactLogsTabPane profileContactLogs={profileContactLogs} isLoading={isLoading}
                                         serviceProfileId={serviceProfileId} customerId={customerId}
                                         onProfileContactRowClicked={onProfileContactLogRowClicked} />
            </TabContent>
          </TabsPanelProvider>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const ServiceGeneratorsTabPane = ({
                                    serviceLocations, isLoading,
                                    onNewCommissionClicked, onGeneratorRowClicked,
                                  }) => {
  return (
    <TabContentPane contentPaneElementId={"service-generators-content"}>
      <div className={"form-section-controls"} style={{ marginTop: "0" }}>
        <button className={"btn btn-primary"} disabled={isLoading()}
                onClick={onNewCommissionClicked}>
          <i className="fa-solid fa-user-gear"></i>
          New Generator Commission
        </button>
      </div>

      <div className={"form-section"}>
        <div className={"data-table"}>
          <table>
            <thead>
            <tr>
              <th scope={"col"} className={"index-col-head"}>#</th>
              <th scope={"col"}>Generator Serial</th>
              <th scope={"col"}>Generator Model</th>
              <th scope={"col"}>T&C/Installation Date</th>
              <th scope={"col"}>Commission Type</th>
              <th scope={"col"}>Warranty Status</th>
            </tr>
            </thead>
            <tbody>
            {isLoading() && <TableLoadingIndicator colspan={6} />}

            {serviceLocations && serviceLocations.length > 0 && !isLoading() &&
              serviceLocations.map((serviceLocation, index) =>
                <ServiceLocationRow serviceLocation={serviceLocation} rowIndex={index} index={index}
                                    key={serviceLocation['id']} onRowClicked={onGeneratorRowClicked}
                />
              )
            }

            {!serviceLocations || (serviceLocations.length === 0 && !isLoading() &&
              <TableEmptyRow colSpan={6} />
            )}
            </tbody>
          </table>
        </div>

      </div>
    </TabContentPane>
  )
}


const ServiceLocationRow = ({
                              serviceLocation, rowIndex, index,
                              onRowClicked,
                            }) => {
  return (
    <>
      <tr className={"highlight"}>
        <td className={"index-col"}>{index + 1}</td>
        <td>
          <span title={"Location"}>
            {serviceLocation['name']}
          </span>
        </td>
        <td colSpan={4}>
          <span title={"City / Township"}>
            {getRegionDisplayByName(serviceLocation['cityName'], serviceLocation['townshipName'])}
          </span>
        </td>
      </tr>
      {serviceLocation && serviceLocation['generators'] && serviceLocation['generators'].length > 0 &&
        serviceLocation['generators'].map((generator, index) =>
          <ServiceGeneratorRow generator={generator} rowIndex={rowIndex} index={index}
                               key={generator['id']} onRowClicked={onRowClicked} />
        )
      }
    </>
  )
}

const ServiceGeneratorRow = ({
                               generator, rowIndex, index,
                               onRowClicked,
                             }) => {
  return (
    <tr>
      <td className={"index-col"}>
        {`${rowIndex + 1}.${index + 1}`}
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           onClick={(ev) => onRowClicked(ev, generator)}>
          {generator['generatorSerial']}
        </a>
      </td>
      <td>
        {generator['gensetModel']}
      </td>
      <td>
        <Moment date={generator['commissionDate']} format={formatters.datetimeShort} />
      </td>
      <td>
        {refs.inventory.serviceGeneratorCommissionType[generator['commissionType']]}
      </td>
      <td>
        {refs.inventory.serviceGeneratorWarrantyStatus[isUnderWarrantyFromStatus(generator['warrantyStatus'])]}
      </td>
    </tr>
  )
}


const ProfileContactLogsTabPane = ({
                                     profileContactLogs, isLoading,
                                     serviceProfileId, customerId,
                                     onProfileContactRowClicked,
                                   }) => {
  const [isNewContactModalOpened, setIsNewContactModalOpened] = useState(false);

  const onNewContactLogClicked = (ev) => {
    newContactModal.onOpenButtonClicked();
  }

  const newContactModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsNewContactModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsNewContactModalOpened(false);
      }, 200);
    },
  }

  return (
    <TabContentPane contentPaneElementId={"profile-contact-logs-content"}>
      <NewContactLogModal isOpen={isNewContactModalOpened}
                          onRequestClose={newContactModal.onCloseButtonClicked}
                          serviceProfileId={serviceProfileId} customerId={customerId} />

      <div className={"form-section-controls"} style={{ marginTop: "0" }}>
        <button className={"btn btn-primary"} disabled={isLoading()}
                onClick={onNewContactLogClicked}>
          <i className="fa-solid fa-plus"></i>
          New Contact Log...
        </button>
      </div>

      <div className={"form-section"}>
        <div className={"data-table"}>
          <table>
            <thead>
            <tr>
              <th scope="col" className={"index-col-head"}>#</th>
              <th scope="col">
                Logged Date
                <SortIndicator isAscending={false} />
              </th>
              <th scope="col">Logged By / Branch</th>
              <th scope="col">Call Type</th>
              <th scope="col">Contact Type</th>
              <th scope="col">Satisfaction</th>
            </tr>
            </thead>
            <tbody>
            {isLoading() && <TableLoadingIndicator colspan={6} />}

            {profileContactLogs && profileContactLogs.length > 0 && !isLoading() &&
              profileContactLogs.map((contactLog, index) =>
                <ProfileContactLogRow contactLog={contactLog} index={index}
                                      key={contactLog['serviceHistoryId']}
                                      onRowClicked={onProfileContactRowClicked} />
              )
            }

            {!profileContactLogs || (profileContactLogs.length === 0 && !isLoading() &&
              <TableEmptyRow colSpan={6} />
            )}
            </tbody>
          </table>
        </div>

      </div>
    </TabContentPane>
  )
}


const callTypes = refs.customer.callType;
const contactTypes = refs.customer.contactType;
const contactSatisfactions = refs.customer.satisfaction;

const ProfileContactLogRow = ({
                                contactLog, index,
                                onRowClicked
                              }) => {
  const getContactSatisfaction = (satisfaction) => {
    if (satisfaction === contactSatisfactions.na) {
      return (
        <span style={{ color: 'gray', fontStyle: 'italic' }}>
          (NA)
        </span>
      )
    } else {
      return <>{contactSatisfactions[satisfaction]}</>
    }
  }

  return (
    <tr>
      <td className={"index-col"}>
        {index + 1}
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           title={"View the contact log entry."}
           onClick={(ev) => onRowClicked(ev, contactLog)}>
          <Moment date={contactLog['recordedDatetime']} format={formatters.datetimeShort} />
        </a>
      </td>
      <td>
        <ActorPureNameDisplay employeeId={contactLog['recordedById']}
                              employeeName={contactLog['recordedByName']} />&nbsp;/&nbsp;
        <span title={"Branch"}>
          {contactLog['recordedByBranchName'] ? contactLog['recordedByBranchName'] : '-'}
        </span>
      </td>
      <td>
        {callTypes[contactLog['callType']]}
      </td>
      <td>
        {contactTypes[contactLog['contactType']]}
      </td>
      <td>
        {getContactSatisfaction(contactLog['contactSatisfaction'])}
      </td>
    </tr>
  )
}
