import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useRef } from "react";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { reportTypes } from "../../repos/constants";
import { viewSettings } from "../../repos/viewContexts";
import { authService } from "../../repos/apiServices";
import { inventoryServices } from "../../repos/apiServices";
import { inventoryServices as inventoryServices2 } from "../../repos/apiServices2";
import { technicalServices } from "../../repos/apiServices2";
import { reportServices } from "../../repos/apiServices2";
import { getListRowSerial } from "../../repos/utilities";
import { getRegionDisplayByName } from "../../repos/utilities";
import { reportStatus } from "../reports/Reports";
import { validateReportDateRanges } from "../reports/Reports";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { WarningMessage } from "../sales/FormMessages";
import { InfoMessage } from "../sales/FormMessages";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { ServiceLocationOptionsField } from "../reports/ServiceLocationOptionsField";
import { CommissionTypeOptionsField } from "../reports/CommissionTypeOptionsField";
import { BusinessTypeOptionsField } from "../reports/BusinessTypeOptionsField";
import { EmployeeOptionsField  } from "../reports/EmployeeOptionsField";

import "../shared/DataTable.css";
import "../shared/ListingPage.css";
import "../reports/ReportEntryPage.css";

const reportStatuses = reportStatus;

export const WorkDoneSummaryReportPage = () => {
  //#region States
  const [title, setTitle] = useState("New Report");
  const [displayTitle, setDisplayTitle] = useState(title);
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(reportStatuses.draft);
  const [primaryUserId, setPrimaryUserId] = useState(null);
  const [isLocked, setIsLocked] = useState(false);

  const [reportId, setReportId] = useState(null);   // Not null in edit mode
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [exceedTargetDuration, setExceedTargetDuration] = useState(true);
  const [exceedRunHours, setExceedRunHours] = useState(true);

  const [reportResults, setReportResults] = useState([]);
  const [reportTotals, setReportTotals] = useState(null);
  const [pagination, setPagination] = useState(null);

  const titleRef = useRef();

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    let _reportId = null;
    if (state !== null) {
      _reportId = state['reportId'];
    }
    // TODO(yemon): refreshing the page in "edit" mode kinda clear the nav 'state' for some reason,
    // and thus resetting the entire page mode to 'new' mode

    if (_reportId !== null) {
      // edit mode
      fetchAndGenerateReport(_reportId);
      setReportId(_reportId);
    }
    else {
      // new mode
      titleRef.current.select();
    }
  }, []);

  useEffect(() => {
    if (title.trim() === "") {
      setDisplayTitle("(Untitled)");
      return;
    }
    setDisplayTitle(title);
  }, [title]);

  const fetchAndGenerateReport = (reportId) => {
    resetListingStates();
    setIsLoading(true);
    reportServices.workDoneSummary.fetch(reportId)
      .then((response) => {
        const _report = response['data'];
        prepareReportDetails(_report);

        generateReport(1, _report['options'], true);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          const responseData = errorResponse.data;
          if (errorResponse.status === 400 || errorResponse.status === 404) {
            navigate(routes.serviceReports_WarrantyStatuses.url);
          }
          if (errorResponse.status === 500) {
            setAlertType(alertTypes.error);
            setAlertMessage("Unknown server error occurred when retrieving the report. Please contact the administrator.");
            setIsAlertVisible(true);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const prepareReportDetails = (report) => {
    if (!report) {
      return;
    }

    const details = report['report'];
    const options = report['options'];

    setReportId(details['id']);
    setTitle(details['title']);
    setDescription(details['description']);

    setIsLocked(details['isLocked']);

    setDateFrom(new Date(options['dateFrom']));
    setDateTo(new Date(options['dateTo']));
    setEmployeeOptions(options['employees']);
    // setLocationOptions(options['locations']);
    // setCommissionTypeOptions(options['commissionTypes']);
    // setBusinessTypeOptions(options['businessTypes']);
  }
  //#endregion

  //#region Control handlers
  const onReportsListClicked = (ev) => {
    navigate(routes.serviceReports_WorkDoneSummary.url);
  }

  const onDateFromChanged = (fromDate) => {
    setDateFrom(fromDate);
  }

  const onDateToChanged = (toDate) => {
    setDateTo(toDate);
  }

  const resetFieldValidations = () => {
    setFormErrors({
      title: [],
      dateFrom: [],
      dateTo: [],
    });
  }

  const isFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      title: [],
      dateFrom: [],
      dateTo: [],
    };
    if (!title || title.trim() === '') {
      _hasErrors = true;
      _formErrors.title = ["Report title is required."];
    }

    let dateValidation = validateReportDateRanges(dateFrom, dateTo);
    if (dateValidation.hasErrors) {
      _hasErrors = true;
      _formErrors.dateFrom = [dateValidation.fromDateError];
      _formErrors.dateTo = [dateValidation.toDateError];
    }

    // if (!employeeOptions || employeeOptions.length === 0) {
    //   _hasErrors = true;
    //   _formErrors.employeeOptions = ["Choose the employees first."];
    // }

    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const prepareReportPayload = () => {
    let desc = description !== null ? description.trim() : null;
    return {
      report: {
        'id': reportId === null ? null : reportId,
        'title': title.trim(),
        'description': desc,
        'reportType': reportTypes.service.workDoneSummary,
        'userId': auth.getUserId()['eid'],
      },
      options: prepareReportOptions(),
    }
  }

  const prepareReportOptions = () => {
    return {
      'dateFrom': dateFrom,
      'dateTo': dateTo,
      'employees': employeeOptions,
      // 'locations': locationOptions,
      // 'commissionTypes': commissionTypeOptions,
      // 'businessTypes': businessTypeOptions,
    }
  }

  const onSaveClicked = (ev) => {
    if (!isFieldsValid()) {
      setHasErrors(true);
      return;
    }
    else {
      setHasErrors(false);
    }

    setIsAlertVisible(false);

    let payload = {
      ...prepareReportPayload(),
    }
    setIsSaving(true);
    reportServices.workDoneSummary.submit(payload)
      .then((response) => {
        let responseData = response['data'];
        setReportId(responseData['id']);

        setAlertType(alertTypes.info);
        setAlertMessage("Report successfully saved.")
        setIsAlertVisible(true);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          const responseData = errorResponse.data;
          handleReportApiErrors(errorResponse);
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  const handleReportApiErrors = (errorResponse) => {
    if (!errorResponse) {
      return;
    }

    const responseData = errorResponse.data;
    if (errorResponse.status === 400 || errorResponse.status === 403) {
      setAlertType(alertTypes.error);
      setAlertMessage(responseData['message']);
      setIsAlertVisible(true);
    }
    if (errorResponse.status === 500) {
      setAlertType(alertTypes.error);
      setAlertMessage("Unknown server error occurred. Please contact the administrator.");
      setIsAlertVisible(true);
    }
  }

  const onCustomerRowClicked = (ev, profile) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': profile['customerId'],
          'serviceProfileId': profile['profileId'],
        }
      });
    }, 200);
  }

  const onGeneratorRowClicked = (ev, profile, generator) => {
    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'generatorId': generator['generatorId'],
          'profileId': profile['profileId'],
          'customerId': profile['customerId'],
        }
      });
    }, 200);
  }

  const onPageClick = (page) => {
    generateReport(page, prepareReportOptions());
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    generateReport(page, prepareReportOptions());
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    generateReport(page, prepareReportOptions());
  }
  //#endregion

  //#region Control handlers; Generate report
  const onGenerateClicked = (ev) => {
    generateReport(1, prepareReportOptions());
  }

  const generateReport = (page, reportOptions, isOnFetch=false) => {
    if (isOnFetch) {
      // NOTE(yemon): A really wacky solution (mainly due to historical cruft),
      // to make sure that the timezone values in the server-parsed datetime strings
      // are not lost between round trips.
      reportOptions['dateFrom'] = new Date(reportOptions['dateFrom']);
      reportOptions['dateTo'] = new Date(reportOptions['dateTo']);
    }
    else {
      if (!isFieldsValid()) {
        setHasErrors(true);
        return;
      }
      else {
        setHasErrors(false);
      }
    }

    let payload = {
      'reportOptions': reportOptions,
      'pagination': {
        'current_page': page,
        'page_size': 10,
      }
    }

    resetListingStates();
    setIsAlertVisible(false);
    setIsGenerating(true);
    reportServices.workDoneSummary.generate(payload)
      .then((response) => {
        let responseData = response['data'];
        setReportResults(responseData['data']);
        setReportTotals(responseData['totals']);
        //updateListingStates(responseData);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          const responseData = errorResponse.data;
          handleReportApiErrors(errorResponse);
        }
      })
      .finally(() => {
        setIsGenerating(false);
      });
  }

  const onClearClicked = (ev) => {
    resetListingStates();
    resetOptionsStates();
    resetFieldValidations();
  }

  const onDeleteClicked = (ev) => {
    //
  }

  const onLockClicked = (ev) => {
    //
  }

  const updateListingStates = (responseData) => {
    setReportResults(responseData['data']);
    setPagination(responseData['pagination']);
  }

  const resetListingStates = () => {
    setReportResults(null);
    setPagination(null);
  }

  const resetOptionsStates = () => {
    setDateFrom(null);
    setDateTo(null);
  }
  //#endregion

  //#region "Generator Locations" field options picker
  const [locationOptions, setLocationOptions] = useState([]);

  const renderLocationOptionsField = () => {
    return (
      <ServiceLocationOptionsField selectedCities={locationOptions} setSelectedCities={setLocationOptions}
                                   disabled={isFormControlsDisabled()} />
    )
  }
  //#endregion

  //#region "Engineers" (employees) field options picker
  const [employeeOptions, setEmployeeOptions] = useState([]);

  const renderEmployeeOptionsField = () => {
    return (
      <EmployeeOptionsField selectedOptions={employeeOptions} 
                            setSelectedOptions={setEmployeeOptions}
                            disabled={isFormControlsDisabled()} />
    )
  }
  //#endregion

  //#region "Commission Types" field options picker
  const [commissionTypeOptions, setCommissionTypeOptions] = useState([]);

  const renderCommissionTypeOptionsField = () => {
    return (
      <CommissionTypeOptionsField selectedOptions={commissionTypeOptions}
                                  setSelectedOptions={setCommissionTypeOptions}
                                  disabled={isFormControlsDisabled()} />
    )
  }
  //#endregion

  //#region "Business Types" field options picker
  const [businessTypeOptions, setBusinessTypeOptions] = useState([]);

  const renderBusinessTypeOptionsField = () => {
    return (
      <BusinessTypeOptionsField selectedOptions={businessTypeOptions}
                                setSelectedOptions={setBusinessTypeOptions}
                                disabled={isFormControlsDisabled()}/>
    )
  }
  //#endregion

  //#region Utilities
  const isFormControlsDisabled = () => {
    return isLoading || isSaving || isGenerating;
  }

  const isAllowDelete = () => {
    return !isLocked;
  }

  const getDateClassName = (isToDate = false) => {
    let className = "form-control md-field";
    if (dateFrom && !isToDate) className += " has-autocompleted-value";
    else if (dateTo && isToDate) className += " has-autocompleted-value";
    return className;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceReports.displayShort} anchorTo={routes.serviceReports.url} />
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onReportsListClicked}>
                  {routes.serviceReports_WorkDoneSummary.displayShort}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={displayTitle} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{displayTitle}</h1>
          </div>

          <div className={"form-section"}>
            <div className={"entry-form"}>
              <div className={"form-message"}>
                {!reportId &&
                  <WarningMessage>
                    The report has <b>not been saved</b> yet. Make sure to save the report in order to reuse the same criteria and results easily.
                  </WarningMessage>
                }
              </div>
            </div>
            <div className={"entry-form report-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"title"}>
                  Title:<span className={"error-message"}>*</span>
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"description"}>
                  Description:
                </label>
              </div>

              <div className={"form-input"}>
                <input type={"text"} id={"title"} className={"form-control md-field"} autoComplete={"off"}
                       autoFocus={true} ref={titleRef}
                       disabled={isFormControlsDisabled()} maxLength={50}
                       value={title} onChange={(ev) => setTitle(ev.target.value)} />
                <FieldErrorMessage visible={hasErrors} message={formErrors['title']} />
              </div>

              <div className={"form-input"}>
                <textarea id={"description"} className={"form-control"} rows={3} disabled={isFormControlsDisabled()}
                          value={description} onChange={(ev) => setDescription(ev.target.value)} maxLength={100}>
                </textarea>
              </div>

              {/*<div className={"form-input-inline-r right-aligned"} style={{ paddingRight: '0' }}>*/}
              {/*  <button type={"button"} className={"btn btn-secondary"} style={{ marginRight: '0' }}*/}
              {/*          disabled={isFormControlsDisabled() || isLocked}*/}
              {/*          onClick={onLockClicked}>*/}
              {/*    {isLocked && <i className={"fa-solid fa-lock"}></i>}*/}
              {/*    {!isLocked && <i className={"fa-solid fa-lock-open"}></i>}*/}
              {/*    {isLocked && "Locked"}*/}
              {/*    {!isLocked && "Lock"}*/}
              {/*  </button>*/}
              {/*</div>*/}
            </div>
          </div>


          <div className={"form-section"}>
            <h2>Report Options</h2>

            <div className={"entry-form report-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"dateFrom"}>
                  From Date:<span className={"error-message"}>*</span>
                </label>
              </div>

              {/* <div className={"form-label"}>
                <label htmlFor={"location"}>
                  Generator Location(s):
                </label>
              </div> */}

              <div className={"form-label"}>
                <label htmlFor={"businessType"}>
                  Engineer(s):
                </label>
              </div>

              <div className={"form-input"}>
                <DatePicker id={"dateFrom"} className={getDateClassName(false)}
                            placeholderText={"Report date from."} required={false} autoComplete={"off"} showWeekNumbers
                            dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                            selected={dateFrom} onChange={onDateFromChanged} />
                <FieldErrorMessage visible={hasErrors} message={formErrors['dateFrom']} />
              </div>

              {/* <div className={"form-input"}>
                {renderLocationOptionsField()}
              </div> */}

              <div className={"form-input"}>
                {renderEmployeeOptionsField()}
                <FieldErrorMessage visible={hasErrors} message={formErrors['employeeOptions']} />
              </div>

              <div className={"form-label-r"}>
                <label htmlFor={"dateTo"}>
                  To Date:<span className={"error-message"}>*</span>
                </label>
              </div>

              <div className={"form-input-r"}>
                <DatePicker id={"dateTo"} className={getDateClassName(true)}
                            placeholderText={"Report date to."} required={false} autoComplete={"off"} showWeekNumbers
                            dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                            selected={dateTo} onChange={onDateToChanged} />
                <FieldErrorMessage visible={hasErrors} message={formErrors['dateTo']} />
              </div>
            </div>
          </div>

          <div className={"form-button-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-primary right-margin"} disabled={isFormControlsDisabled()}
                      onClick={onSaveClicked}>
                {isSaving && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSaving && <i className={"fa-solid fa-check"}></i>}
                Save
              </button>
              <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled() || isLocked}
                      onClick={onGenerateClicked}>
                {isGenerating && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isGenerating && <i className={`fa-solid ${routes.serviceReports.faIcon}`}></i>}
                Generate
              </button>
              <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled() || isLocked}
                      onClick={onClearClicked}>
                <i className={"fa-solid fa-xmark"}></i>
                Clear
              </button>
            </div>

            <div className={"right-side right-aligned"}>
            {/*  <button type={"button"} className={"btn btn-secondary right-margin"}*/}
            {/*          onClick={isFormControlsDisabled()}>*/}
            {/*    <i className="fa-solid fa-sliders"></i>*/}
            {/*    View options...*/}
            {/*  </button>*/}
            {/*  <button type={"button"} className={"btn btn-danger"} style={{ marginRight: '0' }}*/}
            {/*          disabled={isFormControlsDisabled() || !isAllowDelete()}*/}
            {/*          onClick={onDeleteClicked}>*/}
            {/*    <i className={"fa-solid fa-trash-can"}></i>*/}
            {/*    Delete*/}
            {/*  </button>*/}
            </div>
          </div>

          <div className={"form-section table-section"}>
            <div className={"data-table"}>
              <table>
                <thead>
                <tr>
                  <th scope={"col"} className={"index-col-head"}>#</th>
                  <th scope={"col"}>Name</th>
                  <th scope={"col"}>T&C</th>
                  <th scope={"col"}>PM</th>
                  <th scope={"col"}>Regular</th>
                  <th scope={"col"}>Repair</th>
                  <th scope={"col"}>Emergency</th>
                  <th scope={"col"}>Inspection</th>
                  <th scope={"col"}>Total</th>
                </tr>
                </thead>
                <tbody>
                {isLoading && <TableLoadingIndicator colspan={9} />}

                {!isLoading && reportResults && reportResults.length > 0 &&
                  reportResults.map((summary, index) =>
                    <WorkSummaryRow workSummary={summary} index={index} key={summary['id']}
                                    // currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                    onCustomerRowClicked={onCustomerRowClicked}
                                    onGeneratorRowClicked={onGeneratorRowClicked} />
                  )
                }

                {!isLoading && reportTotals && 
                  <tr className={"highlight"}>
                    <td></td>
                    <td>Total</td>
                    <td>
                      {reportTotals['tnc']}
                    </td>
                    <td>
                      {reportTotals['pm']}
                    </td>
                    <td>
                      {reportTotals['regular']}
                    </td>
                    <td>
                      {reportTotals['repair']}
                    </td>
                    <td>
                      {reportTotals['emergency']}
                    </td>
                    <td>
                      {reportTotals['inspection']}
                    </td>
                    <td>
                      {reportTotals['tnc']+reportTotals['pm']+reportTotals['regular']+reportTotals['repair']+reportTotals['emergency']+reportTotals['inspection']}
                    </td>
                  </tr> 
                }

                {!isLoading && (!reportResults || reportResults.length === 0) &&
                  <TableEmptyRow colSpan={9} />
                }
                </tbody>
              </table>
              {/* {pagination &&
                <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                 totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                                 onPageClicked={onPageClick}
                                 onPrevPageClicked={onPrevPageClicked}
                                 onNextPageClicked={onNextPageClicked}
                                 isLoading={isLoading}
                                 viewSettingsNamespace={null}
                                 onViewSettingsSaved={null} />
              } */}
            </div>
          </div>

          <PageAlert visible={isAlertVisible} type={alertType}
                     onDismissButtonClicked={(ev) => setIsAlertVisible(false)}>
            {alertMessage}
          </PageAlert>
        </div>

      </main>
    </MasterPageContainer>
  )
//#endregion
}


const WorkSummaryRow = ({
                          workSummary, index,
                          currentPage, pageSize,
                          onCustomerRowClicked, onGeneratorRowClicked,
                        }) => {
  const getRowSerial = () => {
    //return (pageSize * (currentPage - 1)) + (index + 1);
    return 0;
  }

  return (
    <>
      <tr>
        <td className={"index-col"}>{index + 1}</td>
        <td>
          {/* <a href={"#"} role={"button"} className={"record-link"}
             title={"Employee/Engineer Name"}
             onClick={(ev) => onCustomerRowClicked(ev, workSummary)}>
            {workSummary['customerName']} / {workSummary['companyName'] ? workSummary['companyName'] : '-'}
          </a> */}
          {workSummary['name']}
        </td>
        <td>
          {workSummary['tnc']}
        </td>
        <td>
          {workSummary['pm']}
        </td>
        <td>
          {workSummary['regular']}
        </td>
        <td>
          {workSummary['repair']}
        </td>
        <td>
          {workSummary['emergency']}
        </td>
        <td>
          {workSummary['inspection']}
        </td>
        <td> 
          {workSummary['tnc']+workSummary['pm']+workSummary['regular']+workSummary['repair']+workSummary['emergency']+workSummary['inspection']}
        </td>
      </tr>
      {/* {serviceProfile && serviceProfile['generators'] && serviceProfile['generators'].length > 0 &&
        serviceProfile['generators'].map((generator, index) =>
          <ServiceGeneratorRow generator={generator} profile={serviceProfile}
                               rowSerial={getRowSerial()} index={index}
                               key={generator['id']} onRowClicked={onGeneratorRowClicked} />)
      } */}
    </>
  )
}

const commissionTypes = refs.inventory.serviceGeneratorCommissionType;

const ServiceGeneratorRow = ({
                               generator, profile, rowSerial, index,
                               onRowClicked,
                             }) => {
  const getWarrantyMonthsDisplay = () => {
    const months = generator['warrantyMonths'];
    if (months === 1) {
      return `${months} month`;
    }
    else {
      return `${months} months`;
    }
  }

  return (
    <tr>
      <td className={"index-col"}>
        {`${rowSerial}.${index + 1}`}
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           title={"[Genset Model] Generator Serial"}
           onClick={(ev) => onRowClicked(ev, profile, generator)}>
          [{generator['gensetModel']}] {generator['generatorSerial']}
        </a>
      </td>
      <td>
        <span title={"Location - City / Township"}>
          {getRegionDisplayByName(generator['locationCityName'], generator['locationTownshipName'])}
        </span>
      </td>
      <td>
        {commissionTypes[generator['commissionType']]}
      </td>
      <td>
        <Moment date={generator['commissionDate']} format={formatters.datetimeShort} />
      </td>
      <td>
        {getWarrantyMonthsDisplay()}
      </td>
      <td>
        <Moment date={generator['warrantyExpireDate']} format={formatters.datetimeShort} />
      </td>
    </tr>
  )
}
