import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { useAuth } from "../auth/AuthProvider";
import { portalServices } from "../../repos/apiServices";
import { portalServices as portalServices2 } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";

import { ModalContainer } from "../shared/ModalContainer";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { InfoMessage } from "../sales/FormMessages";
import { ErrorMessage } from "../sales/FormMessages";
import { ReadonlyField } from "../shared/ReadonlyField";
import { AccordionSection } from "../shared/Accordion";
import { AccordionHeader } from "../shared/Accordion";
import { AccordionContent } from "../shared/Accordion";

import "./ServiceProfilePage.css";
import "./EditPortalUserModal.css";

const portalRefs = refs.portal;


export const EditPortalUserModal = ({
                                      serviceProfileId, selectedPortalUserId,
                                      displayFullname, setDisplayFullname,
                                      loginUsername,
                                      generatedPassword,
                                      accountStatus,
                                      resetPortalUserFields,
                                      isOpen, onRequestClose,
                                      onUserSubmitted,
                                      afterUserPasswordReset,
                                      serviceLocations,
                                      checkedServicePermissions,
                                      onGeneratorCheckChanged,
                                      onAllGeneratorsCheckChanged,
                                      onAllLocationsCheckChanged,
                                   }) => {
  //#region States
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingPermissions, setIsSubmittingPermissions] = useState(false);
  const [hasFormAlert, setHasFormAlert] = useState(false);
  const [formAlertMessage, setFormAlertMessage] = useState("You probably did something wrong.");
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const auth = useAuth();
  //#endregion

  //#region Utilities
  const isAccountActive = () => {
    return accountStatus !== portalRefs.accountStatus.draft &&
           accountStatus !== portalRefs.accountStatus.passwordReset;
  }
  //#endregion

  //#region Control handlers
  const onRequestModalClose = () => {
    resetFormFields();
    onRequestClose();
  }

  const resetFormFields = () => {
    resetPortalUserFields();
    setHasErrors(false);
    setFormErrors({});
  }

  const isFormFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      displayFullname: [],
      loginUsername: [],
    };
    if (!displayFullname || displayFullname.trim() === '') {
      _hasErrors = true;
      _formErrors.displayFullname = ["Display name is required."];
    }
    if (!loginUsername || loginUsername.trim() === '') {
      _hasErrors = true;
      _formErrors.loginUsername = ["Login user name is required."];
    }

    setHasErrors(_hasErrors);
    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const preparePayload = () => {
    let employeeId = auth.getUserId()['eid'];
    return {
      'employee_id': employeeId,
      'service_profile_id': serviceProfileId,
      'portal_user_id': selectedPortalUserId,
      'display_fullname': displayFullname.trim(),
    }
  }

  // COPYPASTA(yemon): From `NewPortalUserModal.onCreateClicked()`, except for the postback route
  const submitPortalUserDetails = () => {
    const isValid = isFormFieldsValid();
    if (!isValid) {
      return;
    }

    let payload = preparePayload();
    setHasErrors(false);
    setIsSubmitting(true);
    portalServices.updatePortalUser(payload)
      .then((_) => {
        //resetFormFields();
        onUserSubmitted();
        //onRequestModalClose();
      })
      .catch((error) => {
        const errorResponse = error['response'];
        let _formErrors = {
          displayFullname: [],
          loginUsername: [],
        };

        if (errorResponse && errorResponse.status === 403) {
          // forbidden, due to authentication related stuffs
          return;   // TODO(yemon): Temp!
        }
        if (errorResponse && errorResponse.status === 400) {
          // bad request, due to validation errors
          const responseData = errorResponse['data'];
          _formErrors.displayFullname = responseData['errors']['displayFullname'] ?? [];
          _formErrors.loginUsername = responseData['errors']['loginUsername'] ?? [];
          setFormErrors(_formErrors);
          setHasErrors(true);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const preparePermissionsPayload = () => {
    let employeeId = auth.getUserId()['eid'];
    let payload = {
      'employee_id': employeeId,
      'service_profile_id': serviceProfileId,
      'portal_user_id': selectedPortalUserId,
      'checked_generator_ids': [],
    }

    for (let permission of checkedServicePermissions) {
      payload['checked_generator_ids'].push(permission['serviceGeneratorId']);
    }

    return payload;
  }

  const submitPortalUserPermissions = () => {
    let permissionsPayload = preparePermissionsPayload();

    setIsSubmittingPermissions(true);
    portalServices2.submitPortalUserPermissions(permissionsPayload)
      .then((_) => {
        // TODO(yemon): Do I need to handle the response back from permissions POST?
      })
      .finally(() => {
        setIsSubmittingPermissions(false);
      });
  }

  const onSubmitClicked = (ev) => {
    submitPortalUserDetails();
    submitPortalUserPermissions();
  }

  const isFormDisabled = () => {
    return isSubmitting || isResettingPassword;
  }

  const onResetPasswordClicked = (ev) => {
    ev.preventDefault();
    let employeeId = auth.getUserId()['eid'];
    let payload = {
      'employee_id': employeeId,
      'service_profile_id': serviceProfileId,
      'portal_user_id': selectedPortalUserId,
    };

    setIsResettingPassword(true);
    portalServices.resetPortalUserPassword(payload)
      .then((_) => {
        resetFormFields();
        afterUserPasswordReset(selectedPortalUserId);
      })
      .catch((error) => {
        const errorResponse = error['response'];
        if (errorResponse && errorResponse.status === 403) {
          // forbidden, due to authentication related stuffs
          return;   // TODO: Temp!
        }
      })
      .finally(() => {
        setIsResettingPassword(false);
      })
  }
  //#endregion

  //#region Render
  return (
    <ModalContainer elementId={"new-portal-user-modal"}
                    isOpen={isOpen} onRequestClose={onRequestModalClose} isLoading={false}
                    height={720} title={"Edit Portal User"}
                    shortDescription={"Modify Customer Portal user account, and manage their permission settings..."}>
      <form>
        {hasFormAlert &&
          <div className={"modal-form"}>
            <div className={"form-message"}>
              <ErrorMessage>
                {formAlertMessage}
              </ErrorMessage>
            </div>
          </div>
        }

        <div className={"modal-form"}>
          <div className={"form-label"}>
            <label htmlFor={"displayFullname"}>
              Display Full Name:<span className="error-message">*</span>
            </label>
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"displayFullname"} name={"displayFullname"} className={"form-control"}
                   autoComplete={"off"} disabled={isFormDisabled()} maxLength={100} autoFocus={true}
                   value={displayFullname} onChange={(ev) => setDisplayFullname(ev.target.value)} />
            <FieldErrorMessages visible={hasErrors} messages={formErrors["displayFullname"]} />
          </div>

          <div className={"form-label"}>
            <label htmlFor={"loginUsername"}>
              Login Username:
            </label>
          </div>

          <div className={"form-input"}>
            <ReadonlyField>{loginUsername}</ReadonlyField>
          </div>

          <div className={"form-label"}>
            <label htmlFor={"loginUsername"}>
              Generated Password:
            </label>
          </div>

          <div className={"form-input"}>
            {!isAccountActive() &&
              <ReadonlyField>{generatedPassword}</ReadonlyField>
            }
            {isAccountActive() &&
              <ReadonlyField>
                <i><span style={{ color: "gray" }}>(User specified)</span></i>
              </ReadonlyField>
            }
          </div>

          <div className={"form-input"}>
            <button className={"btn btn-secondary btn-sm"} disabled={isFormDisabled()}
                    onClick={onResetPasswordClicked}>
              {isResettingPassword && <i className="fa-solid fa-circle-notch fa-spin"></i>}
              {!isResettingPassword && <i className={"fa-solid fa-key"}></i>}
              Reset Password
            </button>
          </div>
        </div>

        {accountStatus === portalRefs.accountStatus.passwordReset &&
          <div className={"modal-form"}>
            <div className={"form-message"}>
              <InfoMessage>
                Provide this <b>Login Username</b> and the <b>Generated Password</b> to the respective customer user/representative.
              </InfoMessage>
            </div>
          </div>
        }

        <div className={"modal-contents"}>
          <h3>Commissioned Generators</h3>
        </div>

        {serviceLocations && serviceLocations.length > 0 &&
          <div className={"modal-controls"}>
            <button type={"button"} className={"btn btn-secondary btn-sm"} disabled={isFormDisabled()}
                    onClick={(ev) => onAllLocationsCheckChanged(ev, true)}>
              Select all locations
            </button>
            <button type={"button"} className={"btn btn-secondary btn-sm"} disabled={isFormDisabled()}
                    onClick={(ev) => onAllLocationsCheckChanged(ev, false)}>
              Unselect all locations
            </button>
          </div>
        }

        <div className={"modal-contents no-margin"}>
          {serviceLocations && serviceLocations.length === 0 &&
            <div className={"service-locations-empty"}>
              No locations or service generators commissioned to this customer.
            </div>
          }
          {serviceLocations && serviceLocations.length > 0 &&
            serviceLocations.map((serviceLocation, index) =>
              <AccordionSection key={index}
                                header={<ServiceLocationAccordionHeader
                                  index={index}
                                  serviceLocation={serviceLocation}
                                  checkedServicePermissions={checkedServicePermissions} />}
                                content={<ServiceLocationAccordionContent
                                  index={index}
                                  serviceLocation={serviceLocation}
                                  checkedServicePermissions={checkedServicePermissions}
                                  onGeneratorCheckChanged={onGeneratorCheckChanged}
                                  onAllGeneratorsCheckChanged={onAllGeneratorsCheckChanged} />}
              />
            )
          }
        </div>

        <div className={"modal-controls form-button-controls"}>
          <button type={"button"} className={"btn btn-primary"} disabled={isFormDisabled()}
                  onClick={onSubmitClicked}>
            {isSubmitting && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSubmitting && <i className={"fa-solid fa-user-check"}></i>}
            Submit
          </button>
        </div>
      </form>

    </ModalContainer>
  )
  //#endregion
}

const ServiceLocationAccordionHeader = ({
                                          index,
                                          serviceLocation,
                                          checkedServicePermissions,
                                        }) => {
  const getSelectedGeneratorsCount = () => {
    if (!checkedServicePermissions || checkedServicePermissions.length === 0) {
      return 0;
    }
    let count = 0;
    for (let permission of checkedServicePermissions) {
      if (permission['serviceLocationId'] === serviceLocation['id']) {
        count += 1;
      }
    }
    return count;
  }

  return (
    <AccordionHeader>
      <b>{serviceLocation['name']}</b>
      {serviceLocation && serviceLocation['generators'].length > 0 &&
        <span style={{ marginLeft: "24px" }}>
          {getSelectedGeneratorsCount()} of {serviceLocation['generators'].length} selected
        </span>
      }
    </AccordionHeader>
  )
}

const ServiceLocationAccordionContent = ({
                                           index,
                                           serviceLocation,
                                           checkedServicePermissions,
                                           onGeneratorCheckChanged,
                                           onAllGeneratorsCheckChanged,
                                         }) => {
  const isGeneratorSelected = (serviceGeneratorId) => {
    if (!checkedServicePermissions || checkedServicePermissions.length === 0) {
      return false;
    }
    return checkedServicePermissions.some((permission) => permission['serviceGeneratorId'] === serviceGeneratorId);
  }

  return (
    <AccordionContent>
      {serviceLocation && serviceLocation['generators'].length === 0 &&
        <div className={"service-generator-item-empty"}>
          No service generators in this location.
        </div>
      }

      {serviceLocation && serviceLocation['generators'].length > 0 &&
        <div className={"service-location-controls"}>
          <button type={"button"} className={"btn btn-secondary btn-sm"} disabled={false}
                  onClick={(ev) => onAllGeneratorsCheckChanged(ev, serviceLocation['id'], true)}>
            Select all generators
          </button>

          <button type={"button"} className={"btn btn-secondary btn-sm"} disabled={false}
                  onClick={(ev) => onAllGeneratorsCheckChanged(ev, serviceLocation['id'], false)}>
            Unselect all generators
          </button>
        </div>
      }

      {serviceLocation && serviceLocation['generators'].length > 0 &&
        serviceLocation['generators'].map((serviceGenerator, index) =>
          <div key={index} className={"service-generator-item"}>
            <label htmlFor={`generator-${serviceGenerator['id']}`}>
              <input type={"checkbox"} id={`generator-${serviceGenerator['id']}`} name={`generator-${serviceGenerator['id']}`}
                     checked={isGeneratorSelected(serviceGenerator['id'])}
                     onChange={(ev) => onGeneratorCheckChanged(ev, serviceLocation['id'], serviceGenerator['id'], ev.target.checked)} />
              <span>
                <b>[{serviceGenerator['stock']['generator']['gensetModel']}]</b> {serviceGenerator['stock']['generatorSerial']}
              </span>
            </label>
          </div>
        )
      }
    </AccordionContent>
  )
}
