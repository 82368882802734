import axios from "axios";

let host = "http://localhost:8000/api";
if (process.env.REACT_APP_ENV === "sit") {
  host = "https://sit-ptimis-a77bfb9ea03a.herokuapp.com/api";
} else if (process.env.REACT_APP_ENV === "staging") {
  host = "https://stage-ptimis.herokuapp.com/api";
} else if (process.env.REACT_APP_ENV === "production") {
  host = "https://prod-ptimis.herokuapp.com/api";
}

let portalHost = host + '/p';

const routes = {
  "auth": {
    "token": `${host}/token/`,
    "refresh": `${host}/token/refresh/`,
    "userInfo": `${host}/system/auth/user-info/`,
    "userPermissions": `${host}/system/auth/permissions/`,
  },
  "ref": {
    "businessTypes": `${host}/ref/business-types/`,
    "searchGenerators": `${host}/ref/generators/search/`,
    "searchGeneratorsOrATS": `${host}/ref/generators-ats/search/`,
    "regionsSearch": `${host}/ref/regions/search/`,
    "salesPersonsList": `${host}/ref/sales-persons/`,
    "employeesListByBranch": `${host}/ref/employees-by-branch/`,
  },
  "profiles": {
    "dashboard": `${host}/profiles/dashboard/`,
  },
  "sales": {
    "saveInquiryEntry": `${host}/sales/inquiry-entry/save/`,
    "inquiryContactReviewApprove": `${host}/sales/inquiry-entry/contact-review/approve/`,
    "inquiryContactReviewReject": `${host}/sales/inquiry-entry/contact-review/reject/`,
    "inquiryContactDuplicateCheck": `${host}/sales/inquiry-entry/contact-duplicate-check/`,
    "saveInquiryDetails": `${host}/sales/inquiry-details/save/`,
    "inquiryHistoryReviewApprove": `${host}/sales/inquiry-details/history-review/approve/`,
    "inquiryHistoryReviewReject": `${host}/sales/inquiry-details/history-review/reject/`,
    "inquiryReporterAssign": `${host}/sales/inquiry-details/reporter/assign/`,
    "inquiryReporterUnAssign": `${host}/sales/inquiry-details/reporter/unassign/`,
    "inquiryReporterTransfer": `${host}/sales/inquiry-details/reporter/transfer/`,
    "inquiryList": `${host}/sales/inquiry-list/`,
    "inquiryListSearch": `${host}/sales/inquiry-list/search/`,
    "inquiryListUpdateRequests": `${host}/sales/inquiry-list/update-requests/`,
    "entryHistory": `${host}/sales/inquiry-entry-history/`,
    "inquiryListExport": `${host}/sales/inquiry-list/export/`,
    "dashboardUpcomingReminders": `${host}/sales/dashboard/upcoming-reminders/`,
    "dashboardQuotationRequests": `${host}/sales/dashboard/quotation-requests/`,
    "quotationRequestList": `${host}/sales/quotation-request-list/`,
    "quotationRequestListExport": `${host}/sales/quotation-request-list/export/`,
    "saveCustomerDetails": `${host}/sales/quotation-request/customer-details/save/`,
    "quotationRequestDetails": `${host}/sales/quotation-request-details/`,
    "quotationRequestDetailsRemove": `${host}/sales/quotation-request-details/`,
    "quotationRequestSubmitForReview": `${host}/sales/quotation-request/submit-for-review/`,
    "quotationRequestReview": `${host}/sales/quotation-request/review/`,
    "quotationRequestCancelRequests": `${host}/sales/quotation-request/cancel-requests/`,
    "quotationRequestCancelSubmit": `${host}/sales/quotation-request/cancel/`,
    "quotationRequestCancelReview": `${host}/sales/quotation-request/cancel-review/`,
    "orderConfirmationsList": `${host}/sales/order-confirmation-list/`,
    "orderConfirmationListExport": `${host}/sales/order-confirmation-list/export/`,
    "orderConfirmationsCancelRequestsList": `${host}/sales/order-confirmation-list/cancel-requests/`,
    "orderConfirmationCustomerDetails": `${host}/sales/order-confirmation/customer-details/save/`,
    "orderConfirmationDetails": `${host}/sales/order-confirmation-details/`,
    "orderConfirmationSubmit": `${host}/sales/order-confirmation/submit/`,
    "orderConfirmationReview": `${host}/sales/order-confirmation/review/`,
    "orderConfirmationPaymentBreakdown": `${host}/sales/order-confirmation/payment-breakdown/`,
    "orderConfirmationExport": `${host}/sales/order-confirmation/export/`,
    "orderConfirmationAssign": `${host}/sales/order-confirmation/assign/`,
    "orderConfirmationUnassign": `${host}/sales/order-confirmation/unassign/`,
    "orderConfirmationCancelSubmit": `${host}/sales/order-confirmation/cancel/`,
    "orderConfirmationCancelReview": `${host}/sales/order-confirmation/cancel-review/`,
  },
  "inventory": {
    "stocksList": `${host}/inventory/stocks/`,
    "stockDetails": `${host}/inventory/stocks/`,
    "stockDetailsUpdate": `${host}/inventory/stock-details/`,
    "stockInspections": `${host}/inventory/stock-inspections/`,
    "stockUsage": `${host}/inventory/stock-usage/`,
    "arrivalInspection": `${host}/inventory/arrival-inspection/`,
    "arrivalInspectionSubmit": `${host}/inventory/arrival-inspection/submit/`,
    "arrivalInspectionReview": `${host}/inventory/arrival-inspection/review/`,
    "stockInspectionEntryDetails": `${host}/inventory/stock-inspection/stock/`,
    "stockInspectionSubmit": `${host}/inventory/stock-inspection/submit/`,
    "stockInspectionReview": `${host}/inventory/stock-inspection/review/`,
    "stockInspectionsSearch": `${host}/inventory/stock-inspections-search/`,
    "serviceProfileCustomerSearch": `${host}/inventory/service-profile/customer-search/`,
    "serviceProfileStatus": `${host}/inventory/service-profile-status/`,
    "serviceProfileDetails": `${host}/inventory/service-profile/`,
    "serviceProfileSubmit": `${host}/inventory/service-profile/submit/`,
    "serviceProfileSubmitNewCustomer": `${host}/inventory/service-profile/submit-new-customer/`,
    "serviceProfileLocations": `${host}/inventory/service-profile-locations/`,
    "serviceProfileLocationSubmit": `${host}/inventory/service-profile-location/submit/`,
    "serviceProfileGenerators": `${host}/inventory/service-profile-generators/`,
    "serviceProfileGenerator": `${host}/inventory/service-profile-generator/`,
    "serviceProfileGeneratorSearch": `${host}/inventory/service-profile-generator/search/`,
    "serviceProfileGeneratorSelect": `${host}/inventory/service-profile-generator/select/`,
    "serviceProfileGeneratorRecommission": `${host}/inventory/service-profile-generator/recommission/`,
    "serviceGeneratorCommissions": `${host}/inventory/service-profile-generator-commissions/`,
    "serviceGeneratorJobHistory": `${host}/inventory/service-profile-generator/job-history/`,
    "serviceGeneratorJobEntry": `${host}/inventory/service-profile-generator/job-entry/`,
    "serviceGeneratorTestChecklistDetails": `${host}/inventory/service-profile-generator/test-checklist-details/`,
    "serviceHistoryReview": `${host}/inventory/service-history/review/`,
    "jobHistorySearch": `${host}/inventory/job-history-search/`,
  },
  "customer": {
    "profileStatus": `${host}/customer/profile-status/`,
    "list": `${host}/customer/list/`,
    "careHistoryContactSubmit": `${host}/customer/care/history-contact/`,
    "careProfileContactSubmit": `${host}/customer/care/profile-contact/`,
    "careContactLogsSearch": `${host}/customer/care/contact-logs-search/`,
  },
  "portal": {
    "portalUsers": `${portalHost}/portal-users/`,
    "portalUserSubmit": `${portalHost}/portal-user/`,
    "portalUserResetPassword": `${portalHost}/portal-user/reset-password/`,
    "portalUsersPermissions": `${portalHost}/portal-users-permissions/`,
    "portalUserPermissions": `${portalHost}/portal-user-permissions/`,
  },
}

const options = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
};

const getAuthenticatedOptions = () => {
  let token = localStorage.getItem('token');
  if (token == null) return options;
  let authOptions = options;
  authOptions.headers['Authorization'] = `Bearer ${token}`;
  return authOptions;
}

const getAuthenticatedBlobOptions = () => {
  let options = {
    'responseType': 'blob',
  };
  let token = localStorage.getItem('token');
  if (token == null) return options;
  options['headers'] = {
    'Authorization': `Bearer ${token}`,
  };
  return options;
}


export const authService = {
  tokenizedLogin: function(loginInfo) {
    return axios.post(routes.auth.token, loginInfo, options);
  },

  tokenRefresh: function() {
    const payload = {
      'refresh': localStorage.getItem('refresh')
    };
    return axios.post(routes.auth.refresh, payload, options);
  },

  fetchUserInfo: function(username, accessToken) {
    let authOptions = options;
    authOptions.headers['Authorization'] = `Bearer ${accessToken}`;
    return axios.get(routes.auth.userInfo + username, authOptions);
  },

  fetchUserPermissions: function(userIdentifier) {
    return axios.post(routes.auth.userPermissions, userIdentifier, getAuthenticatedOptions());
  }
}


export const refServices = {
  fetchBusinessTypes: function() {
    return axios.get(routes.ref.businessTypes, getAuthenticatedOptions());
  },

  searchGenerators: function(term) {
    return axios.get(routes.ref.searchGenerators + term, getAuthenticatedOptions());
  },

  searchGeneratorsOrATS: function(term) {
    return axios.get(routes.ref.searchGeneratorsOrATS + term, getAuthenticatedOptions());
  },

  searchRegions: function(term) {
    return axios.get(routes.ref.regionsSearch + "?term=" + term, getAuthenticatedOptions());
  },

  fetchSalesPersonsList: function(payload) {
    return axios.post(routes.ref.salesPersonsList, payload, getAuthenticatedOptions());
  },

  fetchEmployeesListByBranch: function(payload) {
    return axios.post(routes.ref.employeesListByBranch, payload, getAuthenticatedOptions());
  },
}



export const profilesServices = {
  fetchDashboardProfile: function(userIdentifier) {
    return axios.post(routes.profiles.dashboard, userIdentifier, getAuthenticatedOptions());
  },
}

export const salesServices = {
  saveSalesInquiryEntry: function(payload) {
    return axios.post(routes.sales.saveInquiryEntry, payload, getAuthenticatedOptions());
  },

  updateSalesInquiryContactDetails: function(payload) {
    return axios.put(routes.sales.saveInquiryEntry, payload, getAuthenticatedOptions());
  },

  approveContactDetailsReview: function(payload) {
    return axios.post(routes.sales.inquiryContactReviewApprove, payload, getAuthenticatedOptions());
  },

  rejectContactDetailsReview: function(payload) {
    return axios.post(routes.sales.inquiryContactReviewReject, payload, getAuthenticatedOptions());
  },

  checkInquiryContactDuplicate: function(payload) {
    return axios.post(routes.sales.inquiryContactDuplicateCheck, payload, getAuthenticatedOptions());
  },

  saveSalesInquiryHistory: function(payload) {
    return axios.post(routes.sales.saveInquiryDetails, payload, getAuthenticatedOptions());
  },

  updateSalesInquiryHistoryDetails: function(payload) {
    return axios.put(routes.sales.saveInquiryDetails, payload, getAuthenticatedOptions());
  },

  approveInquiryHistoryReview: function(payload) {
    return axios.post(routes.sales.inquiryHistoryReviewApprove, payload, getAuthenticatedOptions());
  },

  rejectInquiryHistoryReview: function(payload) {
    return axios.post(routes.sales.inquiryHistoryReviewReject, payload, getAuthenticatedOptions());
  },

  reporterAssign: function(payload) {
    return axios.post(routes.sales.inquiryReporterAssign, payload, getAuthenticatedOptions());
  },

  reporterUnAssign: function(payload) {
    return axios.post(routes.sales.inquiryReporterUnAssign, payload, getAuthenticatedOptions());
  },

  reporterTransfer: function(payload) {
    return axios.post(routes.sales.inquiryReporterTransfer, payload, getAuthenticatedOptions());
  },

  inquiryList: function(listPayload) {
    return axios.post(routes.sales.inquiryList, listPayload, getAuthenticatedOptions());
  },

  inquiryListUpdateRequests: function(tableOptions) {
    return axios.post(routes.sales.inquiryListUpdateRequests, tableOptions, getAuthenticatedOptions());
  },

  fetchInquiryEntryHistory: function(initialEntryId) {
    return axios.get(routes.sales.entryHistory + initialEntryId, getAuthenticatedOptions());
  },

  postInquiryListExport: function(searchOptionsPayload) {
    return axios.post(routes.sales.inquiryListExport, searchOptionsPayload, getAuthenticatedBlobOptions());
  },

  fetchDashboardUpcomingReminders: function(optionsPayload) {
    return axios.post(routes.sales.dashboardUpcomingReminders, optionsPayload, getAuthenticatedOptions());
  },

  fetchDashboardQuotationRequests: function(optionsPayload) {
    return axios.post(routes.sales.dashboardQuotationRequests, optionsPayload, getAuthenticatedOptions());
  },

  quotationRequestList: function(tableOptions) {
    return axios.post(routes.sales.quotationRequestList, tableOptions, getAuthenticatedOptions());
  },

  postQuotationRequestListExport: function(searchOptionsPayload) {
    return axios.post(routes.sales.quotationRequestListExport, searchOptionsPayload, getAuthenticatedBlobOptions());
  },

  saveQuotationRequestCustomerDetails: function(payload) {
    return axios.post(routes.sales.saveCustomerDetails, payload, getAuthenticatedOptions());
  },

  updateQuotationRequestCustomerDetails: function(payload) {
    return axios.put(routes.sales.saveCustomerDetails, payload, getAuthenticatedOptions());
  },

  fetchQuotationRequestDetails: function(quotationRequestId) {
    return axios.get(routes.sales.quotationRequestDetails + quotationRequestId, getAuthenticatedOptions());
  },

  addQuotationRequestDetails: function(payload) {
    // return axios.post(routes.sales.quotationRequestDetailsAdd, payload, getAuthenticatedOptions());
    return axios.post(routes.sales.quotationRequestDetails, payload, getAuthenticatedOptions());
  },

  updateQuotationRequestDetails: function(payload) {
    // return axios.post(routes.sales.quotationRequestDetailsAdd, payload, getAuthenticatedOptions());
    return axios.put(routes.sales.quotationRequestDetails, payload, getAuthenticatedOptions());
  },

  removeQuotationRequestDetails: function(quotationRequestId, quotationRequestDetailsId) {
    // return axios.post(routes.sales.quotationRequestDetailsAdd, payload, getAuthenticatedOptions());
    return axios.delete(routes.sales.quotationRequestDetailsRemove + quotationRequestId + '/' + quotationRequestDetailsId, getAuthenticatedOptions());
  },

  submitQuotationRequestForReview: function(payload) {
    return axios.post(routes.sales.quotationRequestSubmitForReview, payload, getAuthenticatedOptions());
  },

  approveOrRejectQuotationRequest: function(payload) {
    return axios.post(routes.sales.quotationRequestReview, payload, getAuthenticatedOptions());
  },

  fetchQuotationRequestCancelRequests: function(tableOptions) {
    return axios.post(routes.sales.quotationRequestCancelRequests, tableOptions, getAuthenticatedOptions());
  },

  submitQuotationRequestCancel: function(payload) {
    return axios.post(routes.sales.quotationRequestCancelSubmit, payload, getAuthenticatedOptions());
  },

  reviewQuotationRequestCancel: function(payload) {
    return axios.post(routes.sales.quotationRequestCancelReview, payload, getAuthenticatedOptions());
  },

  orderConfirmationsList: function(tableOptions) {
    return axios.post(routes.sales.orderConfirmationsList, tableOptions, getAuthenticatedOptions());
  },

  postOrderConfirmationListExport: function(listOptions) {
    return axios.post(routes.sales.orderConfirmationListExport, listOptions, getAuthenticatedBlobOptions());
  },

  orderConfirmationCancelRequestsList: function(tableOptions) {
    return axios.post(routes.sales.orderConfirmationsCancelRequestsList, tableOptions, getAuthenticatedOptions());
  },

  saveOrderConfirmationCustomerDetails: function(payload) {
    return axios.post(routes.sales.orderConfirmationCustomerDetails, payload, getAuthenticatedOptions());
  },

  updateOrderConfirmationCustomerDetails: function(payload) {
    return axios.put(routes.sales.orderConfirmationCustomerDetails, payload, getAuthenticatedOptions());
  },

  fetchOrderConfirmationDetails: function(orderConfirmationID) {
    return axios.get(routes.sales.orderConfirmationDetails + orderConfirmationID, getAuthenticatedOptions());
  },

  addOrderConfirmationDetails: function(payload) {
    return axios.post(routes.sales.orderConfirmationDetails, payload, getAuthenticatedOptions());
  },

  updateOrderConfirmationDetails: function(payload) {
    return axios.put(routes.sales.orderConfirmationDetails, payload, getAuthenticatedOptions());
  },

  removeOrderConfirmationDetails: function(orderConfirmationId, orderConfirmationDetailsId) {
    return axios.delete(routes.sales.orderConfirmationDetails + orderConfirmationId + '/' + orderConfirmationDetailsId, getAuthenticatedOptions());
  },

  submitOrderConfirmation: function(payload) {
    return axios.post(routes.sales.orderConfirmationSubmit, payload, getAuthenticatedOptions());
  },

  approveOrRejectOrderConfirmation: function(payload) {
    return axios.post(routes.sales.orderConfirmationReview, payload, getAuthenticatedOptions());
  },

  fetchOrderConfirmationPaymentBreakdown: function(orderConfirmationId) {
    return axios.get(routes.sales.orderConfirmationPaymentBreakdown + orderConfirmationId, getAuthenticatedOptions());
  },

  saveOrderConfirmationPaymentBreakdown: function(payload) {
    return axios.post(routes.sales.orderConfirmationPaymentBreakdown, payload, getAuthenticatedOptions());
  },

  updateOrderConfirmationPaymentBreakdown: function(payload) {
    return axios.put(routes.sales.orderConfirmationPaymentBreakdown, payload, getAuthenticatedOptions());
  },

  postOrderConfirmationExport: function(payload) {
    return axios.post(routes.sales.orderConfirmationExport, payload, getAuthenticatedBlobOptions());
  },

  orderConfirmationCCAssign: function(payload) {
    return axios.post(routes.sales.orderConfirmationAssign, payload, getAuthenticatedOptions());
  },

  orderConfirmationCCUnassign: function(payload) {
    return axios.post(routes.sales.orderConfirmationUnassign, payload, getAuthenticatedOptions());
  },

  submitOrderConfirmationCancel: function(payload) {
    return axios.post(routes.sales.orderConfirmationCancelSubmit, payload, getAuthenticatedOptions());
  },

  reviewOrderConfirmationCancel: function(payload) {
    return axios.post(routes.sales.orderConfirmationCancelReview, payload, getAuthenticatedOptions());
  },
}

export const inventoryServices = {
  getStocksList: function(listPayload) {
    return axios.post(routes.inventory.stocksList, listPayload, getAuthenticatedOptions());
  },

  fetchStockDetails: function(params) {
    return axios.get(routes.inventory.stockDetails + params['stock_id'], getAuthenticatedOptions());
  },

  updateStockDetails: function(payload) {
    return axios.put(routes.inventory.stockDetailsUpdate, payload, getAuthenticatedOptions());
  },

  fetchStockInspections: function(payload) {
    return axios.post(routes.inventory.stockInspections + payload['stock_id'], payload, getAuthenticatedOptions());
  },

  checkStockUsage: function(params) {
    return axios.get(routes.inventory.stockUsage + params['stock_id'], getAuthenticatedOptions());
  },

  fetchArrivalInspectionEntry: function(params) {
    return axios.get(routes.inventory.arrivalInspection + params['stock_id'] + '/' + params['inspection_id'],
      getAuthenticatedOptions()
    );
  },

  postArrivalInspection: function(payload) {
    return axios.post(routes.inventory.arrivalInspectionSubmit, payload, getAuthenticatedOptions());
  },

  postArrivalInspectionReview: function(payload) {
    return axios.post(routes.inventory.arrivalInspectionReview, payload, getAuthenticatedOptions());
  },

  fetchPredeliveryInspectionStockDetails: function(payload) {
    return axios.post(routes.inventory.stockInspectionEntryDetails, payload, getAuthenticatedOptions());
  },

  postPredeliveryInspection: function(payload) {
    return axios.post(routes.inventory.stockInspectionSubmit, payload, getAuthenticatedOptions());
  },

  searchStockInspections: function(payload) {
    return axios.post(routes.inventory.stockInspectionsSearch, payload, getAuthenticatedOptions());
  },

  postPredeliveryInspectionReview: function(payload) {
    return axios.post(routes.inventory.stockInspectionReview, payload, getAuthenticatedOptions());
  },

  searchCustomersForProfile: function(payload) {
    return axios.post(routes.inventory.serviceProfileCustomerSearch, payload, getAuthenticatedOptions());
  },

  fetchCustomerServiceProfileStatus: function(customerId) {
    return axios.get(routes.inventory.serviceProfileStatus + customerId, getAuthenticatedOptions());
  },

  fetchCustomerServiceProfile: function(params) {
    return axios.get(routes.inventory.serviceProfileDetails + params['customer_id'] + '/' + params['service_profile_id'],
        getAuthenticatedOptions()
    );
  },

  postCustomerServiceProfile: function(payload) {
    return axios.post(routes.inventory.serviceProfileSubmit, payload, getAuthenticatedOptions());
  },

  postNewCustomerServiceProfile: function(payload) {
    return axios.post(routes.inventory.serviceProfileSubmitNewCustomer, payload, getAuthenticatedOptions());
  },

  fetchServiceProfileGenerators: function(serviceProfileId) {
    return axios.get(routes.inventory.serviceProfileGenerators + serviceProfileId, getAuthenticatedOptions());
  },

  fetchServiceProfileGenerator: function(params) {
    return axios.get(routes.inventory.serviceProfileGenerator + params['service_profile_id'] + '/' + params['service_generator_id'],
      getAuthenticatedOptions()
    );
  },

  postServiceProfileGeneratorSearch: function(payload) {
    return axios.post(routes.inventory.serviceProfileGeneratorSearch, payload, getAuthenticatedOptions());
  },

  postServiceProfileGeneratorSelect: function(payload) {
    return axios.post(routes.inventory.serviceProfileGeneratorSelect, payload, getAuthenticatedOptions());
  },

  postServiceProfileGeneratorRecommission: function(payload) {
    return axios.post(routes.inventory.serviceProfileGeneratorRecommission, payload, getAuthenticatedOptions());
  },

  fetchServiceGeneratorCommissions: function(payload) {
    const url = `${routes.inventory.serviceGeneratorCommissions}${payload['service_generator_id']}`;
    return axios.get(url, getAuthenticatedOptions());
  },

  fetchServiceGeneratorJobHistory: function(payload) {
    return axios.post(routes.inventory.serviceGeneratorJobHistory, payload, getAuthenticatedOptions());
  },

  fetchServiceGeneratorJobEntry: function(payload) {
    return axios.post(routes.inventory.serviceGeneratorJobEntry, payload, getAuthenticatedOptions());
  },

  submitServiceGeneratorJobEntry: function(payload) {
    return axios.put(routes.inventory.serviceGeneratorJobEntry, payload, getAuthenticatedOptions());
  },

  submitServiceGeneratorTestChecklistDetails: function(payload) {
    return axios.post(routes.inventory.serviceGeneratorTestChecklistDetails, payload, getAuthenticatedOptions());
  },

  deleteServiceGeneratorTestChecklistDetails: function(testChecklistId, testChecklistDetailsId) {
    const url = `${routes.inventory.serviceGeneratorTestChecklistDetails}${testChecklistId}/${testChecklistDetailsId}`;
    return axios.delete(url, getAuthenticatedOptions());
  },

  submitServiceHistoryReview: function(payload) {
    return axios.post(routes.inventory.serviceHistoryReview, payload, getAuthenticatedOptions());
  },

  fetchServiceProfileLocations: function(serviceProfileId) {
    return axios.get(routes.inventory.serviceProfileLocations + serviceProfileId, getAuthenticatedOptions());
  },

  submitServiceProfileLocation: function(payload) {
    return axios.post(routes.inventory.serviceProfileLocationSubmit, payload, getAuthenticatedOptions());
  },

  updateServiceProfileLocation: function(payload) {
    return axios.put(routes.inventory.serviceProfileLocationSubmit, payload, getAuthenticatedOptions());
  },

  searchJobHistories: function(payload) {
    return axios.post(routes.inventory.jobHistorySearch, payload, getAuthenticatedOptions());
  },
}

export const customerServices = {
  setProfileStatus: function(payload) {
    return axios.post(routes.customer.profileStatus, payload, getAuthenticatedOptions());
  },

  fetchCustomerProfiles: function(tableOptions) {
    return axios.post(routes.customer.list, tableOptions, getAuthenticatedOptions());
  },

  submitCareHistoryContact: function(payload) {
    return axios.post(routes.customer.careHistoryContactSubmit, payload, getAuthenticatedOptions());
  },

  submitCareProfileContact: function(payload) {
    return axios.post(routes.customer.careProfileContactSubmit, payload, getAuthenticatedOptions());
  },

  fetchCareProfileContactEntry: function(payload) {
    const url = `${routes.customer.careProfileContactSubmit}${payload['service_profile_id']}/${payload['service_history_id']}`
    return axios.get(url, getAuthenticatedOptions());
  },


  searchCareContactLogs: function(payload) {
    return axios.post(routes.customer.careContactLogsSearch, payload, getAuthenticatedOptions());
  },
}

export const portalServices = {
  fetchPortalUsers: function(serviceProfileId) {
    return axios.get(routes.portal.portalUsers + serviceProfileId, getAuthenticatedOptions());
  },

  submitPortalUser: function(payload) {
    return axios.post(routes.portal.portalUserSubmit, payload, getAuthenticatedOptions());
  },

  updatePortalUser: function(payload) {
    return axios.put(routes.portal.portalUserSubmit, payload, getAuthenticatedOptions());
  },

  resetPortalUserPassword: function(payload) {
    return axios.post(routes.portal.portalUserResetPassword, payload, getAuthenticatedOptions());
  },

  fetchPortalUsersPermissions: function(serviceProfileId) {
    return axios.get(routes.portal.portalUsersPermissions + serviceProfileId, getAuthenticatedOptions());
  },

  fetchPortalUserPermissions: function(serviceProfileId, portalUserId) {
    // NOTE(yemon): API2 ported
    return axios.get(routes.portal.portalUserPermissions + serviceProfileId + '/' + portalUserId, getAuthenticatedOptions());
  },

  submitPortalUserPermissions: function(payload) {
    // NOTE(yemon): API2 ported
    return axios.post(routes.portal.portalUserPermissions, payload, getAuthenticatedOptions());
  },
}
