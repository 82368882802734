import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices";
import { inventoryServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { formatTownshipDisplay } from "../../repos/utilities";
import { getListRowSerial } from "../../repos/utilities";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { InfoMessage } from "../sales/FormMessages";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { StockStatusBadge } from "./StockStatusBadge";
import { NullBlankValue } from "./NullBlankField";

import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "../shared/DataTable.css";
import "./ServiceProfilePage.css";
import "./ServiceSearchForm.css";


const generatorManufacturers = refs.inventory.generatorManufacturers;
const generatorAlternators = refs.inventory.generatorAlternators;
const generatorMachines = refs.inventory.generatorMachines;
const generatorControllers = refs.inventory.generatorControllers;
const commissionTypes = refs.inventory.serviceGeneratorCommissionType;
const stockStatus = refs.inventory.stockStatus;

export function NewCommissionEntryPage() {
  //#region States
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedProfileId, setSelectedProfileId] = useState(null);

  const [customerName, setCustomerName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [businessType, setBusinessType] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [townshipId, setTownshipId] = useState(null);
  const [regionTerm, setRegionTerm] = useState("");
  const [regionSuggestions, setRegionSuggestions] = useState([]);
  const [commissionType, setCommissionType] = useState(commissionTypes.pti);
  const [installationDate, setInstallationDate] = useState(new Date());
  const [minInstallationDate, setMinInstallationDate] = useState(null);
  const [warrantyMonths, setWarrantyMonths] = useState(0);
  const [warrantyHours, setWarrantyHours] = useState("");

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("Hi there!");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [isSubmittingForReview, setIsSubmittingForReview] = useState(false);
  const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [isFetchingLocations, setIsFetchingLocations] = useState(false);
  const [profileLocations, setProfileLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  const [isBeingSelected, setIsBeingSelected] = useState(false);
  const [selectedStockId, setSelectedStockId] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    if (!state) {
      navigate(routes.serviceProfiles.url);
    }
    let _customerId = state['customerId'];
    let _profileId = state['profileId'];
    if (!_customerId || !_profileId) {
      navigate(routes.serviceProfiles.url);
    }
    else {
      setSelectedCustomerId(_customerId);
      setSelectedProfileId(_profileId);
      fetchCustomerServiceProfile(_customerId, _profileId);
      fetchProfileLocations(_profileId);
    }
  }, []);

  const fetchCustomerServiceProfile = (customerId, serviceProfileId) => {
    let params = {
      'uid': auth.getUserId(),
      'customer_id': customerId,
      'service_profile_id': serviceProfileId,
    };
    setIsLoading(true);
    inventoryServices.fetchCustomerServiceProfile(params)
      .then((response) => {
        let _serviceProfile = response['data'];
        prepareCustomerDetails(_serviceProfile['customer']);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          if (errorResponse.status === 404 || errorResponse.status === 400) {
            navigate(routes.serviceProfiles.url);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const prepareCustomerDetails = (customer) => {
    if (!customer) {
      return;
    }
    setCustomerName(customer['customerName']);
    setBusinessType(customer['businessType']);
  }

  const fetchProfileLocations = (profileId) => {
    if (!profileId) {
      return;
    }

    setIsFetchingLocations(true);
    inventoryServices.fetchServiceProfileLocations(profileId)
      .then((response) => {
        const _profileLocations = response.data;
        setProfileLocations(_profileLocations);
        if (_profileLocations && _profileLocations.length > 0) {
          setSelectedLocationId(_profileLocations[0]['id']);
        }
      })
      .finally(() => {
        setIsFetchingLocations(false);
      });
  }
  //#endregion

  //#region Utilities
  //#endregion

  //#region Control handlers; Customer section
  const onLocationRegionChanged = (ev, region, prevRegion) => {
    setFormErrors({ ...formErrors, location: '' });
    if (region.trim() === '') {
      setRegionTerm('');
      setCityId(null);
      setTownshipId(null);
      return false;
    }
    if (region === prevRegion) {
      return false;
    }
    setRegionTerm(region);
    setCityId(null);
    setTownshipId(null);
    return true;
  }

  const onLocationSuggestionCityClicked = (ev, city) => {
    setRegionTerm(city['name']);
    setCityId(city['id']);
    setTownshipId(null);
  }

  const onLocationSuggestionTownshipClicked = (ev, township) => {
    setRegionTerm(formatTownshipDisplay(township));
    setCityId(township['cityId']);
    setTownshipId(township['id']);
  }

  const onCommissionTypeChanged = (ev) => {
    setCommissionType(parseInt(ev.target.value));
  }

  const onInstallationDateChanged = (date) => {
    setInstallationDate(date);
  }
  //#endregion

  //#region Control handlers; Control buttons, messages and API callbacks
  const onAlertDismissButtonClicked = () => {
    setIsAlertVisible(false);
  }

  const onCustomerNameClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': selectedCustomerId,
          'serviceProfileId': selectedProfileId,
        }
      });
    }, 200);
  }

  const resetAlertMessage = () => {
    setIsAlertVisible(false);
    setAlertType(alertTypes.info);
    setAlertMessage("");
  }

  const resetSearchSelectionStates = () => {
    setIsBeingSelected(false);
    setSelectedStockId(null);
  }

  const isWarrantyHoursValid = () => {
    if (!warrantyHours) {
      return true;
    }
    const iWarrantyHours = parseInt(warrantyHours.toString());
    return !(isNaN(iWarrantyHours) || !isFinite(iWarrantyHours));
  }

  const isCustomerCommissionFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      installationDate: "",
      location: "",
    };

    // TODO(yemon): Specified 'installation date' should at least be validated against
    // the very first 'Arrival Inspection date' of the selected stock.
    if (!installationDate) {
      _hasErrors = true;
      _formErrors.installationDate = "Installation date is required.";
    }
    if (selectedLocationId === null) {
      _hasErrors = true;
      _formErrors.location = "No service location was applicable (or) selected to commission a new generator.";
    }

    if (warrantyHours) {
      if (!isWarrantyHoursValid()) {
        _hasErrors = true;
        _formErrors.warrantyHours = "Enter a valid number value for the warranty hours.";
      }
      else {
        const iWarrantyHours = parseInt(warrantyHours.toString());
        if (iWarrantyHours < 0 || iWarrantyHours > 99999) {
          _hasErrors = true;
          _formErrors.warrantyHours = "Warranty hours value should be within a positive number range.";
        }
      }
    }

    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const prepareCustomerCommissionPayload = () => {
    let payload = {
      'commission_type': commissionType,
      'installation_date': installationDate,
    }
    if (warrantyMonths !== 0) {
      payload['warranty_months'] = warrantyMonths;
    }
    if (warrantyHours && isWarrantyHoursValid()) {
      payload['warranty_hours'] = parseInt(warrantyHours.toString());
    }
    return payload;
  }

  const onStockSelectClicked = (ev, stock) => {
    resetAlertMessage();
    let _isValid = isCustomerCommissionFieldsValid();
    if (!_isValid) {
      setHasErrors(true);
      setAlertMessage("Please fix the indicated errors above before submitting again.");
      setAlertType(alertTypes.error);
      setIsAlertVisible(true);
      return;
    }
    else {
      setHasErrors(false);
      setIsAlertVisible(false);
    }

    let payload = {
      ...prepareCustomerCommissionPayload()
    }
    payload['employee_id'] = auth.getUserId()['eid'];
    payload['profile_id'] = selectedProfileId;
    payload['location_id'] = selectedLocationId;
    payload['stock_id'] = stock['stockId'];

    setIsBeingSelected(true);
    setSelectedStockId(stock['stockId']);
    inventoryServices.postServiceProfileGeneratorSelect(payload)
      .then((response) => {
        let _responseData = response['data'];
        setAlertType(alertTypes.info);
        setAlertMessage("New generator commissioned to the profile. Redirecting to T&C...");
        setIsAlertVisible(true);

        let serviceGeneratorId = _responseData['serviceGeneratorId'];
        let newTNCHistoryId = _responseData['newTNCHistoryId'];

        navigateToServiceHistoryEntry(serviceGeneratorId, newTNCHistoryId);
      })
      .catch((error) => {
        let _errorResponse = error['response'];
        setAlertType(alertTypes.error);
        if (_errorResponse['status'] === 500) {
          setAlertMessage("Something went wrong during the server postback. Please contact the administrator.");
        }
        else {
          setAlertMessage(_errorResponse['data']['message']);
        }
        setIsAlertVisible(true);
      })
      .finally(() => {
        setIsBeingSelected(false);
      })
  }

  const navigateToServiceHistoryEntry = (serviceGeneratorId, serviceHistoryId) => {
    setTimeout(() => {
      navigate(routes.testingAndCommission.url, {
        state: {
          'serviceProfileId': selectedProfileId,
          'serviceGeneratorId': serviceGeneratorId,
          'serviceHistoryId': serviceHistoryId,
        }
      });
    }, 200);
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfiles.displayShort} anchorTo={routes.serviceProfiles.url} />
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onCustomerNameClicked}>
                  {customerName}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={routes.newGeneratorCommission.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>New Generator Commission</h1>
          </div>

          <CustomerDetailsSection customerName={customerName} businessType={businessType}
                                  profileLocations={profileLocations} selectedLocationId={selectedLocationId} setSelectedLocationId={setSelectedLocationId}
                                  isSubmitting={isSubmitting || isSubmittingDraft || isSubmittingForReview}
                                  isBeingSelected={isBeingSelected}
                                  hasErrors={hasErrors} formErrors={formErrors}
          />

          <CommissionDetailsSection cityId={cityId} townshipId={townshipId}
                                    commissionType={commissionType} onCommissionTypeChanged={onCommissionTypeChanged}
                                    installationDate={installationDate} onInstallationDateChanged={onInstallationDateChanged}
                                    minInstallationDate={null} isEditing={isEditable}
                                    warrantyMonths={warrantyMonths} setWarrantyMonths={setWarrantyMonths}
                                    warrantyHours={warrantyHours} setWarrantyHours={setWarrantyHours}
                                    isSubmitting={isSubmitting || isSubmittingDraft || isSubmittingForReview}
                                    isBeingSelected={isBeingSelected}
                                    hasErrors={hasErrors} formErrors={formErrors}
          />

          <SearchGeneratorFormSection selectedProfileId={selectedProfileId}
                                      onStockSelectClicked={onStockSelectClicked}
                                      onReturnClicked={onCustomerNameClicked}
                                      isBeingSelected={isBeingSelected} selectedStockId={selectedStockId}
                                      resetSearchSelectionStates={resetSearchSelectionStates}
                                      resetAlertMessage={resetAlertMessage}
          />

          <PageAlert visible={isAlertVisible} type={alertType}
                     onDismissButtonClicked={onAlertDismissButtonClicked}>
            {alertMessage}
          </PageAlert>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const CustomerDetailsSection = ({
                                  customerName, businessType,
                                  profileLocations, selectedLocationId, setSelectedLocationId,
                                  isEditing, isSubmitting, hasErrors, formErrors, isBeingSelected,
                                }) => {
  const [contactName, setContactName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [address, setAddress] = useState("");

  const onLocationSelected = (ev) => {
    if (!profileLocations || profileLocations.length === 0) {
      return;
    }
    const _locationId = ev.target.value;
    setSelectedLocationId(_locationId);

    const _location = profileLocations.find((location) => location['id'] === _locationId);
    setContactName(_location['contactFullName']);
    setContactNo(_location['contactNo']);
    setAddress(_location['address']);
  }

  useEffect(() => {
    if (!profileLocations || profileLocations.length === 0) {
      return;
    }

    const _location = profileLocations[0];
    setContactName(_location['contactFullName']);
    setContactNo(_location['contactNo']);
    setAddress(_location['address']);
  }, [profileLocations]);

  return (
    <div className={"form-section"}>
      <h2>Customer Details</h2>

      <div className={"entry-form service-profile-form"}>
        <div className={"form-label"}>
          <label>
            Customer Name:
          </label>
        </div>

        <div className={"form-label"}>
          <label>
            Location:<span className={"error-message"}>*</span>
          </label>
        </div>

        <div className={"form-label"}>
          <label>
            Location Address:
          </label>
        </div>

        <div className={"form-input"}>
          <ReadonlyField>
            {customerName}
          </ReadonlyField>
        </div>

        <div className={"form-input"}>
          {(!profileLocations || profileLocations.length === 0) &&
            <i>(No service locations setup for this profile)</i>
          }
          {profileLocations && profileLocations.length > 0 &&
            <select name={"serviceLocation"} id={"serviceLocation"} className={"form-control form-select"}
                    disabled={isBeingSelected} value={selectedLocationId}
                    onChange={onLocationSelected}>
              {profileLocations.map((location, index) =>
                <option value={location['id']} key={index}>{location['name']}</option>
              )}
            </select>
          }
          <FieldErrorMessage visible={hasErrors} message={formErrors["location"]} />
        </div>

        <div className={"form-input"}>
          <ReadonlyField>
            <NullBlankValue value={address} />
          </ReadonlyField>
        </div>

        <div className={"form-label-r"}>
          <label>
            Business Type:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label>
            Location Contact Name:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label>
            Location Contact No:
          </label>
        </div>

        <div className={"form-input-r"}>
          <ReadonlyField>
            {businessType && businessType['name']}
            {!businessType && '-'}
          </ReadonlyField>
        </div>

        <div className={"form-input-r"}>
          <ReadonlyField>
            <NullBlankValue value={contactName} />
          </ReadonlyField>
        </div>

        <div className={"form-input-r"}>
          <ReadonlyField>
            <NullBlankValue value={contactNo} />
          </ReadonlyField>
        </div>

      </div>

    </div>
  )
}


const CommissionDetailsSection = ({
                                    commissionType, onCommissionTypeChanged,
                                    installationDate, onInstallationDateChanged, minInstallationDate,
                                    warrantyMonths, setWarrantyMonths,
                                    warrantyHours, setWarrantyHours,
                                    isEditing, isSubmitting, hasErrors, formErrors, isBeingSelected,
                                  }) => {
  const getInstallationDateClassName = () => {
    let className = "form-control";
    if (installationDate) className += " has-autocompleted-value";
    return className;
  }

  const onWarrantyMonthsChanged = (ev) => {
    if (!isNaN(ev.target.value)) {
      setWarrantyMonths(null);
    }
    let months = parseInt(ev.target.value);
    if (months === 0) {
      setWarrantyMonths(null);
    } else {
      setWarrantyMonths(months);
    }
  }

  const onWarrantyHoursChanged = (ev) => {
    // TODO(yemon): Warranty hours needs to be sanitized as an integer
    setWarrantyHours(ev.target.value);
  }

  return (
    <div className={"form-section"}>
      <h2>New Commission Details</h2>

      <div className={"entry-form service-profile-form"}>
        <div className={"form-label"}>
          <label htmlFor={"commissionType"}>
            Commission Type:<span className={"error-message"}>*</span>
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"installationDate"}>
            Installation Date:<span className={"error-message"}>*</span>
          </label>
        </div>

        <div className={"form-input"}>
          <select name={"commissionType"} id={"commissionType"} className={"form-control form-select"}
                  disabled={isBeingSelected}
                  value={commissionType} onChange={onCommissionTypeChanged}>
            <option value={commissionTypes.pti}>
              {commissionTypes[commissionTypes.pti]}
            </option>
            <option value={commissionTypes.ptiContract}>
              {commissionTypes[commissionTypes.ptiContract]}
            </option>
            <option value={commissionTypes.otherContract}>
              {commissionTypes[commissionTypes.otherContract]}
            </option>
          </select>
        </div>

        <div className={"form-input"}>
          <DatePicker id={"installationDate"} className={getInstallationDateClassName()}
                      placeholder={"Type a valid date or click to choose"}
                      dateFormat={formatters.datetimePicker} minDate={minInstallationDate}
                      required={true} todayButton={"Today"} showWeekNumbers
                      autoComplete={"off"} disabled={isBeingSelected}
                      selected={installationDate} onChange={onInstallationDateChanged} />
          <FieldErrorMessage visible={hasErrors} message={formErrors["installationDate"]} />
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"warrantyMonths"}>
            Warranty Months:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"warrantyHours"}>
            Warranty Hours:
          </label>
        </div>

        <div className={"form-input-r"}>
          <select name={"warrantyMonths"} id={"warrantyMonths"} className={"form-control form-select"}
                  disabled={isBeingSelected}
                  value={warrantyMonths} onChange={onWarrantyMonthsChanged}>
            <option value={0}>(Unspecified)</option>
            <option value={1}>1 month</option>
            {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month, index) =>
              <option value={month} key={index}>{month} months</option>
            )}
            <option value={12}>12 months (1 year)</option>
            {[13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map((month, index) =>
              <option value={month} key={index}>{month} months (1 year, {month - 12} months)</option>
            )}
            <option value={24}>24 months (2 years)</option>
          </select>
        </div>

        <div className={"form-input-r"}>
          <input type={"text"} id={"warrantyHours"} className={"form-control"} autoComplete={"off"}
                 disabled={isBeingSelected}
                 value={warrantyHours} onChange={onWarrantyHoursChanged} />
          <FieldErrorMessage visible={hasErrors} message={formErrors["warrantyHours"]} />
        </div>

      </div>
    </div>
  )
}


const SearchGeneratorFormSection = ({
                                      selectedProfileId,
                                      onStockSelectClicked, onReturnClicked,
                                      isBeingSelected, selectedStockId,
                                      resetSearchSelectionStates,
                                      resetAlertMessage,
                                    }) => {
  const [generatorSerial, setGeneratorSerial] = useState("");
  const [gensetModel, setGensetModel] = useState("");
  const [manufacturer, setManufacturer] = useState(generatorManufacturers.any);
  const [alternatorSerial, setAlternatorSerial] = useState("");
  const [alternatorModel, setAlternatorModel] = useState("");
  const [alternator, setAlternator] = useState(generatorAlternators.any);
  const [machineSerial, setMachineSerial] = useState("");
  const [machineModel, setMachineModel] = useState("");
  const [machine, setMachine] = useState(generatorMachines.any);
  const [controllerSerial, setControllerSerial] = useState("");
  const [controllerModel, setControllerModel] = useState("");
  const [controller, setController] = useState(generatorControllers.any);

  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [generatorStocks, setGeneratorStocks] = useState([]);
  const [pagination, setPagination] = useState(null);

  const auth = useAuth();

  //#region Effects
  //#endregion

  //#region Control handlers
  const isFormControlsDisabled = () => {
    return isSearchLoading || isBeingSelected;
  }

  const onSearchButtonClicked = (ev) => {
    validateAndTriggerSearch(1);
    if (resetSearchSelectionStates) resetSearchSelectionStates();
    if (resetAlertMessage) resetAlertMessage();
  }

  const validateAndTriggerSearch = (page) => {
    let listPayload = prepareListPayload(page);
    listPayload['search_options'] = prepareSearchOptions();
    triggerSearch(listPayload);
  }

  const prepareListPayload = (page) => {
    //let settings = getViewSettings(viewSettings.salesInquiries);
    return {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
        //'pageSize': settings ? settings['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
        'page_size': DEFAULT_LIST_PAGE_SIZE,
      }
    }
  }

  const prepareSearchOptions = () => {
    return {
      'generator_serial': generatorSerial.trim(),
      'genset_model': gensetModel.trim(),
      'manufacturer_id': manufacturer,
      'alternator_serial': alternatorSerial.trim(),
      'alternator_model': alternatorModel.trim(),
      'alternator_id': alternator,
      'machine_serial': machineSerial.trim(),
      'machine_model': machineModel.trim(),
      'machine_id': machine,
      'controller_serial': controllerSerial.trim(),
      'controller_model': controllerModel.trim(),
      'controller_id': controller,
    }
  }

  const triggerSearch = (listPayload) => {
    resetListingStates();
    setIsSearchLoading(true);

    inventoryServices.postServiceProfileGeneratorSearch(listPayload)
      .then((response) => {
        let responseData = response['data'];
        updateListingStates(responseData);
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => {
        setIsSearchLoading(false);
      });
  }

  const onClearButtonClicked = (ev) => {
    // TODO(yemon): Clear the listing and search panel context
    resetSearchFields();
    resetListingStates();
    if (resetSearchSelectionStates) resetSearchSelectionStates();
    if (resetAlertMessage) resetAlertMessage();
  }

  const updateListingStates = (responseData) => {
    setGeneratorStocks(responseData['data']);
    setPagination(responseData['pagination']);
    // TODO(yemon): Update listing context
  }

  const resetListingStates = () => {
    setGeneratorStocks([]);
    setPagination(null);
  }

  const resetSearchFields = () => {
    setGeneratorSerial("");
    setGensetModel("");
    setManufacturer(generatorManufacturers.any);
    setAlternatorSerial("");
    setAlternatorModel("");
    setAlternator(generatorAlternators.any);
    setMachineSerial("");
    setMachineModel("");
    setMachine("");
    setControllerSerial("");
    setControllerModel("");
    setController("");
  }

  const onPageClick = (page) => {
    validateAndTriggerSearch(page);
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    validateAndTriggerSearch(page);
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    validateAndTriggerSearch(page);
  }
  //#endregion

  //#region Render
  return (
    <form onSubmit={onSearchButtonClicked}>
      <div className={"form-section"}>
        <h2>Search Generator</h2>

        <div className={"entry-form"}>
          <div className={"form-message"}>
            <InfoMessage>
              Find and select an <b>Available</b> generator first, in order to commission for the selected customer profile...
            </InfoMessage>
          </div>
        </div>

        <div className={"entry-form generator-section"}>
          <div className={"form-label"}>
            <label htmlFor={"generatorSerial"}>
              Generator Serial Number:
            </label>
          </div>

          <div className={"form-label"}>
            <label htmlFor={"generatorModel"}>
              Generator Model:
            </label>
          </div>

          <div className={"form-label"}>
            <label htmlFor={"generator"}>
              Generator/Manufacturer:
            </label>
          </div>

          <div className={"form-label"}>
            <label htmlFor={"alternatorSerial"}>
              Alternator Serial Number:
            </label>
          </div>

          <div className={"form-label"}>
            <label htmlFor={"alternatorModel"}>
              Alternator Model:
            </label>
          </div>

          <div className={"form-label"}>
            <label htmlFor={"alternator"}>
              Alternator:
            </label>
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"generatorSerial"} className={"form-control"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()}
                   value={generatorSerial} onChange={(ev) => setGeneratorSerial(ev.target.value)} />
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"gensetModel"} className={"form-control"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()}
                   value={gensetModel} onChange={(ev) => setGensetModel(ev.target.value)} />
          </div>

          <div className={"form-input"}>
            <select name={"manufacturer"} id={"manufacturer"} className={"form-control form-select"}
                    disabled={isFormControlsDisabled()}
                    value={manufacturer} onChange={(ev) => setManufacturer(parseInt(ev.target.value))}>
              <option value={0}>- Any manufacturer -</option>
              <option value={generatorManufacturers.AKSA}>
                {generatorManufacturers[generatorManufacturers.AKSA]}
              </option>
            </select>
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"alternatorSerial"} className={"form-control"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()}
                   value={alternatorSerial} onChange={(ev) => setAlternatorSerial(ev.target.value)} />
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"alternatorModel"} className={"form-control"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()}
                   value={alternatorModel} onChange={(ev) => setAlternatorModel(ev.target.value)} />
          </div>

          <div className={"form-input"}>
            <select name={"alternator"} id={"alternator"} className={"form-control form-select"}
                    disabled={isFormControlsDisabled()}
                    value={alternator} onChange={(ev) => setAlternator(parseInt(ev.target.value))}>
              <option value={0}>- Any alternator -</option>
              <option value={generatorAlternators.AKSA}>
                {generatorAlternators[generatorAlternators.AKSA]}
              </option>
              <option value={generatorAlternators.Stamford}>
                {generatorAlternators[generatorAlternators.Stamford]}
              </option>
            </select>
          </div>

          <div className={"form-label-r"}>
            <label htmlFor={"machineSerial"}>
              Machine Serial Number:
            </label>
          </div>

          <div className={"form-label-r"}>
            <label htmlFor={"machineModel"}>
              Machine Model:
            </label>
          </div>

          <div className={"form-label-r"}>
            <label htmlFor={"machine"}>
              Machine:
            </label>
          </div>

          <div className={"form-label-r"}>
            <label htmlFor={"controllerSerial"}>
              Controller Serial Number:
            </label>
          </div>

          <div className={"form-label-r"}>
            <label htmlFor={"controllerModel"}>
              Controller Model:
            </label>
          </div>

          <div className={"form-label-r"}>
            <label htmlFor={"controller"}>
              Controller:
            </label>
          </div>

          <div className={"form-input-r"}>
            <input type={"text"} id={"machineSerial"} className={"form-control"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()}
                   value={machineSerial} onChange={(ev) => setMachineSerial(ev.target.value)} />
          </div>

          <div className={"form-input-r"}>
            <input type={"text"} id={"machineModel"} className={"form-control"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()}
                   value={machineModel} onChange={(ev) => setMachineModel(ev.target.value)} />
          </div>

          <div className={"form-input-r"}>
            <select name={"manufacturer"} id={"manufacturer"} className={"form-control form-select"}
                    disabled={isFormControlsDisabled()}
                    value={machine} onChange={(ev) => setMachine(parseInt(ev.target.value))}>
              <option value={0}>- Any machine -</option>
              <option value={generatorMachines.AKSA}>
                {generatorMachines[generatorMachines.AKSA]}
              </option>
              <option value={generatorMachines.Cummins}>
                {generatorMachines[generatorMachines.Cummins]}
              </option>
              <option value={generatorMachines.Mitsubishi}>
                {generatorMachines[generatorMachines.Mitsubishi]}
              </option>
              <option value={generatorMachines.Perkins}>
                {generatorMachines[generatorMachines.Perkins]}
              </option>
            </select>
          </div>

          <div className={"form-input-r"}>
            <input type={"text"} id={"controllerSerial"} className={"form-control"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()}
                   value={controllerSerial} onChange={(ev) => setControllerSerial(ev.target.value)} />
          </div>

          <div className={"form-input-r"}>
            <input type={"text"} id={"controllerModel"} className={"form-control"} autoComplete={"off"}
                   disabled={isFormControlsDisabled()}
                   value={controllerModel} onChange={(ev) => setControllerModel(ev.target.value)} />
          </div>

          <div className={"form-input-r"}>
            <select name={"controller"} id={"controller"} className={"form-control form-select"}
                    disabled={isFormControlsDisabled()}
                    value={controller} onChange={(ev) => setController(parseInt(ev.target.value))}>
              <option value={0}>- Any controller -</option>
              <option value={generatorControllers.deepSea}>
                {generatorControllers[generatorControllers.deepSea]}
              </option>
            </select>
          </div>

        </div>
      </div>

      <div className={"form-button-controls"}>
        <div className={"left-side"}>
          <button type={"button"} className={"btn btn-secondary right-margin"} disabled={isFormControlsDisabled()}
                  onClick={onReturnClicked}>
            <i className={"fa-solid fa-arrow-left"}></i>
            Return to Profile
          </button>
          <button type={"submit"} className={"btn btn-success"} disabled={isFormControlsDisabled()}
                  onClick={onSearchButtonClicked}>
            {isSearchLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSearchLoading && <i className="fa-solid fa-magnifying-glass"></i>}
            Search
          </button>
          <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled()}
                  onClick={onClearButtonClicked}>
            <i className="fa-solid fa-xmark"></i>
            Clear
          </button>
        </div>
      </div>

      <div className={"form-section table-section"}>
        <div className={"data-table service-search-results-table"}>
          <table>
            <thead>
              <SearchGeneratorTableHeading />
            </thead>
            <tbody>
            {isSearchLoading && <TableLoadingIndicator colspan={6} /> }

            {generatorStocks && generatorStocks.length > 0 && (!isSearchLoading) &&
              generatorStocks.map((stock, index) =>
                <SearchGeneratorTableRow key={stock['stockId']} stock={stock} index={index} selectedProfileId={selectedProfileId}
                                         currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                         isBeingSelected={isBeingSelected} selectedStockId={selectedStockId}
                                         onRowSelectClicked={onStockSelectClicked}
                />
              )
            }

            {!generatorStocks || (generatorStocks.length === 0 && !isSearchLoading &&
                <TableEmptyRow colSpan={6} />
            )}
            </tbody>
          </table>
          {pagination &&
            <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                             totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                             onPageClicked={onPageClick} onPrevPageClicked={onPrevPageClicked}
                             onNextPageClicked={onNextPageClicked} isLoading={isSearchLoading}
            />
          }
        </div>
      </div>
    </form>
  )
  //#endregion
}


const SearchGeneratorTableHeading = () => {
  return (
    <tr>
      <th scope="col" className="index-col-head">#</th>
      <th scope="col">Generator Serial</th>
      <th scope="col">Generator Model</th>
      <th scope="col">Stock Status</th>
      <th scope="col">Last Inspection</th>
      <th scope="col">Last Inspection On</th>
    </tr>
  )
}


const stockInspectionType = refs.inventory.stockInspectionType;

const SearchGeneratorTableRow = ({
                                   stock, index,
                                   selectedProfileId,
                                   currentPage, pageSize,
                                   onRowSelectClicked,
                                   isBeingSelected, selectedStockId,
                                   hasErrors, errorMessage,
                                 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);
  const [isCached, setIsCached] = useState(false);

  const onExpandActionClicked = (ev) => {
    ev.preventDefault();
    fetchGeneratorStock(stock['stockId']);
  }

  const fetchGeneratorStock = (selectedStockId) => {
    if (isExpanded) {
      setIsExpanded(false);
      return;
    }
    if (isCached) {
      setIsExpanded(true);
      return;
    }

    setIsExpanded(true);
    setIsCached(true);
  }

  const isRowBeingSelected = () => {
    if (!selectedStockId) return false;
    return isBeingSelected && stock['stockId'] === selectedStockId;
  }

  const isRowControlsDisabled = () => {
    return isExpanding || isBeingSelected;
  }

  return (
    <>
      <tr>
        <td className={"index-col"}>{getListRowSerial(pageSize, currentPage, index)}</td>
        <td className={isExpanding ? "primary-col expandable-action action-expanding" : "primary-col expandable-action"}>
          <a href={"#"} role={"button"} onClick={onExpandActionClicked}>
            <span>
              {stock['generatorSerial']}
            </span>
            {isExpanding && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isExpanding && !isExpanded && <i className="fa-solid fa-square-plus"></i>}
            {!isExpanding && isExpanded && <i className="fa-solid fa-square-minus"></i>}
          </a>
        </td>
        <td className={"secondary-col"}>
          {stock['gensetModel']}
        </td>
        <td className={"tertiary-col"}>
          <StockStatusBadge status={stock['stockStatus']} />
        </td>
        <td>
          {stock['lastInspectionType'] === stockInspectionType.imported &&
            <span style={{ color: 'gray', fontStyle: "italic" }}>
            {stockInspectionType[stock['lastInspectionType']]}
          </span>
          }
          {stock['lastInspectionType'] !== stockInspectionType.imported &&
            <span>{stockInspectionType[stock['lastInspectionType']]}</span>
          }
        </td>
        <td>
          {stock['lastInspectionType'] === stockInspectionType.imported &&
            <span style={{ color: 'gray', fontStyle: "italic" }}>
            (NA)
          </span>
          }
          {stock['lastInspectionType'] !== stockInspectionType.imported &&
            <Moment date={stock['lastInspectionDatetime']} format={formatters.datetimeShort} />
          }
        </td>
      </tr>

      {isExpanded &&
        <>
          <tr className={"solid-background"}>
            <td className={"index-col"}></td>
            <td className={"inline-field-label"}>
              Manufacturer:
            </td>
            <td>
              {/*{getManufacturerDisplay(stock['manufacturer'])}*/}
              {generatorManufacturers[stock['manufacturer']]}
            </td>
            <td colSpan={3}></td>
          </tr>
          <tr className={"solid-background"}>
            <td className={"index-col"}></td>
            <td className={"inline-field-label"}>
              Alternator:
            </td>
            <td>
              {/*{getAlternatorDisplay(stock['alternator'])}*/}
              {generatorAlternators[stock['alternator']]}
            </td>
            <td colSpan={3}></td>
          </tr>
          <tr className={"solid-background"}>
            <td className={"index-col"}></td>
            <td className={"inline-field-label"}>
              Machine:
            </td>
            <td>
              {generatorMachines[stock['machine']]}
            </td>
            <td colSpan={3}></td>
          </tr>
          <tr className={"solid-background"}>
            <td className={"index-col"}></td>
            <td className={"inline-field-label"}>
              Controller:
            </td>
            <td>
              {/*{stock['controller'] ?? '-'}*/}
              {generatorControllers[stock['controller']]}
            </td>
            <td colSpan={3}></td>
          </tr>

          <tr className={"solid-background bottom-bordered"}>
            <td className={"index-col"}></td>
            <td colSpan={5}>
              <div className={"inline-section-controls"}>
                {hasErrors &&
                  <div className={"inline-field-errors"}>
                    <FieldErrorMessage visible={hasErrors} message={errorMessage} />
                  </div>
                }
                <button type={"button"} className={"btn btn-primary"} disabled={isRowControlsDisabled()}
                        onClick={(ev) => onRowSelectClicked(ev, stock)}>
                  {isRowBeingSelected() && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                  {!isRowBeingSelected() && <i className="fa-solid fa-check-to-slot"></i>}
                  Select for commissioning
                </button>
              </div>
            </td>
          </tr>
        </>
      }

    </>
  )
}
