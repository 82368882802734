import { alertTypes } from "../components/shared/PageAlert";

export const handleApiErrorAlert = (errorResponse, setAlertType, setAlertMessage, setIsAlertVisible) => {
  if (!errorResponse) {
    return;
  }

  const responseData = errorResponse.data;
  if (errorResponse.status === 404) {
    // NOTE(yemon): Shouldn't happen normally!
    setAlertType(alertTypes.error);
    setAlertMessage("The API route for this action cannot be found. (Status: 404)");
    setIsAlertVisible(true);
  }
  if (errorResponse.status === 400 || errorResponse.status === 403) {
    setAlertType(alertTypes.error);
    setAlertMessage(responseData['message']);
    setIsAlertVisible(true);
  }
  if (errorResponse.status === 500) {
    setAlertType(alertTypes.error);
    setAlertMessage("Unknown server error occurred. Please contact the administrator.");
    setIsAlertVisible(true);
  }
}
